import PageContainer from "../components/PageContainer";
import {Hotline as BauHotline, StyledButton,Tickets} from "@bau/material";
import {translate} from "../utils/utils";
import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import {useLocation, useNavigate} from "react-router-dom";

const Hotline = () => {

    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    const navigate = useNavigate();
    let {state} = useLocation();
    const lang=user.data?.settings.lang||"DE"
    const customerId = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentCustomer]);
    let number = '072148699530';
    let emailAddress = 'support@openexperience.de';
    let message = translate('popup.hotline');
    if (user.clientOf === 'bilfinger'){
        number = '0080008000888';
        message = translate('popup.hotline.bilfinger');
    } else if (user.clientOf === 'pmg'){
        message = translate('popup.hotline.pmg');
        number = '0893303782100';
        emailAddress = 'info@pmgnet.de';
    } else if (user.clientOf === 'brz') {
        message = translate('popup.hotline.brz');
        emailAddress = 'baudokumentation.support.de@brz.eu';
    }

    const goBack = () => {
        if(!!state && !!state.helpTextId) {
            navigate(`/help/${state.helpTextId}`);
        } else {
            navigate("/help");
        }

    }

    return (
        <PageContainer newPage={true}
                       left={<StyledButton onClick={goBack}>{translate("back")}</StyledButton>}
                       headerText={translate("web.hotline")} >
            <BauHotline
                email={emailAddress} phoneNumber={number}
                workHours={"Mo.-Fr. 8:00-17:00"} numberString={translate("web.telephone.number")} workHourString={translate("web.working.hours")}
                callSupportStrig={"web.hotline.callhotline"} writeEmailString={"web.hotline.writeemail"}
            />
            <Tickets lang={lang} groupName={customerId}/>
        </PageContainer>
    )
}

export default Hotline;