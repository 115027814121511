define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', './DefectsPageHtml', '../view/BaseView',
	'../model/currentProject', '../model/currentInspection', '../model/currentDefects', '../model/Defect', '../model/user', '../model/subcontractors', '../model/inspections',
	'../view/Autocomplete', '../view/MultiSelect', '../view/ReducedDefectsBanner', '../model/completions', '../model/attachmentSources', '../helper/watchdog', '../helper/iosapp', '../helper/shortcuts/shortcuts', '../helper/offlineQueue', '../helper/idutil', '../helper/backboneReactCommunicationUtils', '../helper/appStateComm', '../lib/moment-2.5.1', '../lib/jquery.scrollTo'
], function (
	$, _, Backbone, Template, BaseView,
	currentProject, currentInspection, currentDefects, Defect,  user, subcontractors, inspections,
	Autocomplete, MultiSelect, ReducedDefectsBanner, completions, attachmentSources, watchdog, iosapp, shortcuts, offlineQueue, idutil, backboneReactCommunicationUtils, appStateComm, moment,
) {
	return BaseView.extend({

		id: 'defects',
		className: 'defectsPage',
		selectAllDefects: false,
		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		showFirstCount: 0,
		itemCount: 0,
		pageSize: 30,
		orderBy: 'numberdesc',
		groupBy: 'status',
		filterMode: 'filter',
		checkedIds: [],
		firstPageShow: true,

		linkDefectsParams: undefined,

		escalationFilters: [],
		terminationFilter: [],
		deadlineFilters: [],


		events: {
			'click .previousPage': function (e) {
				var customerProject = backboneReactCommunicationUtils.getCustomerProject();
				var customerProjectWithInspection = backboneReactCommunicationUtils.getCustomerProjectConsideringInspection();
				var pagePath = '';
				switch (this.previousPage) {
					case 'inspection':       pagePath = customerProjectWithInspection;  break;
					case 'inspections':      pagePath = customerProject+'/inspections'; break;
					case 'location':         pagePath = customerProjectWithInspection + '/location'; break;
					case 'principal-bundle': pagePath = customerProjectWithInspection + '/principalBundle'; break;
					default:                 pagePath = customerProject; break;
				}
				window.navigateCallback(pagePath);
			},
			'orientationchange':                 function() { $.mobile.resetActivePageHeight(); this.resize(); },
			'change #defectSubcontractorFilter': 'filter',
			'change #defectInspectionFilter':    'filter',
			'change #defectStatusFilter':        'filter',
			'change #defectTypeFilter':          'filter',
			'change .filterMode':				 'changeMode',
			'keyup  #defectSearchFilter':        'filterKeyUp',
			'change #locationFilter select':     'filter',
			'change .search-location input[type="text"]': 'searchLocationsFromInput',
			'click  .defects-only-favourite':    'toggleFavouriteDefects',

			'click .close-defects-menu-btn': function(e) {
				event.preventDefault();
				$('.defects-quick-actions').addClass("hide-menu");
				this.refreshSelections('deselect');
			},

			'change .deadlinecg': function (e) {
				var $fieldSet = $(e.currentTarget).children('.ui-controlgroup-controls ');

				var $labelSetDeadline = $(e.currentTarget).find('#set-deadline-for-selected-defects').prev();

				if ($(e.currentTarget).find('#set-deadline-for-selected-defects').prop("checked")) {
					$fieldSet.children().last().css('display', 'block');
					$labelSetDeadline.css({
						'border-top-right-radius'	: '0px',
						'border-bottom-right-radius': '0px',
						'border-right-width'		: '0px'
					});
				} else {
					if ($fieldSet.children().length == 3) {
						$fieldSet.children().last().css('display', 'none');
						$labelSetDeadline.css({
							'border-top-right-radius'	: '7px',
							'border-bottom-right-radius': '7px',
							'border-right-width'		: '1px'
						});
					}

					//$(e.currentTarget).children('.ui-controlgroup-controls ')[0].children().last().remove();
				}
			},

			'click .list .ui-checkbox':          function (e) {

				$('#defects-actions-menu > .edit-defects').toggle(!this.linkDefectsParams);
				$('#defects-actions-menu > .defect-action-export').toggle(!this.linkDefectsParams);
				$('#defects-actions-menu > .sendChosen').toggle(!this.linkDefectsParams);
				$('#defects-actions-menu > .link-defects').toggle(!!this.linkDefectsParams);

				var $input = $(e.currentTarget).children('input.selectItem');
				var state = $input.prop("checked");
				if (!$(e.target).is('input')) {
					state = !state;
					$input.prop("checked", state).focus();
				}
				var id = parseInt($input.attr('data-id'));
				var elem = $input.closest('li');
				var lastElem = this.lastElem;
				if(lastElem && e.shiftKey && lastElem[0] !== elem[0]) {
					state = lastElem.find('input.selectItem').prop('checked');
					var list = elem.closest('ul').children();
					var elemsToSelect = (list.index(elem) < list.index(lastElem) ? elem.nextUntil(lastElem).add(elem) : lastElem.nextUntil(elem).add(elem));
					var deferred = $.Deferred().resolve();
					deferred = deferred.then(function () {
						_.each(elemsToSelect, function (e) {
							$(e).find('a.ui-btn').toggleClass('highlight-defect', state);
							$(e).find('input.selectItem').prop('checked', state);
							var inputId = parseInt($(e).find('input.selectItem').attr('data-id'));
							if($(e).find('input.selectItem').length > 0) {
								if (state && !this.checkedIds.includes(inputId)) this.checkedIds.push(inputId);
								if (!state) this.checkedIds.splice(_.indexOf(this.checkedIds, inputId), 1);
							}
						}.bind(this))
					}.bind(this));
					deferred.then(function () {
						$('.defects-quick-actions').toggleClass('hide-menu', this.checkedIds.length === 0).toggleClass('active-menu', this.checkedIds.length !== 0)
						this.$defectsCount.text(this.checkedIds.length);
					}.bind(this));
				} else {
					if (state) {
						$input.parent().siblings().addClass("highlight-defect");
						if ($('.defects-quick-actions').hasClass("hide-menu")) {
							$('.defects-quick-actions').removeClass("hide-menu");
						}
						$('.defects-quick-actions').addClass("active-menu");

						this.checkedIds.push(id);
						this.$defectsCount.text(this.checkedIds.length);
					} else {
						this.checkedIds.splice(_.indexOf(this.checkedIds, id), 1);
						$input.parent().siblings().removeClass("highlight-defect");
						$input.parent().siblings().removeClass("highlight-all");
						if (this.checkedIds.length == 0) {
							$('.defects-quick-actions').addClass("hide-menu");
						}
						this.$defectsCount.text(this.checkedIds.length);
					}
				}
				this.lastElem = $input.closest('li');
				$('#defects-actions-menu label').each(function () {
					if($(this).is(':visible')) {
						pWidth = $(this).parent().outerWidth();
						width = $(this).outerWidth();
						$(this).css('left', pWidth / 2 - width / 2);
					}
				});
				this.refreshDownloadOption();
			},
			'vclick .moreRows button':           function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.renderList({ dontEmpty: true });
				var $children = this.$list.children();
				var $lastElement = $children.get($children.length - 1);
				// this.$list.scrollTo($lastElement, 500);
			},
			'vclick .newDefect':                 function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (currentInspection && currentInspection.get('id') && $(e.target).data('alternatetype') && $(e.target).data('alternatetype').length > 0) {
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defect/c'+ $(e.target).data('alternatetype'));
				} else {
					var path = backboneReactCommunicationUtils.getCustomerProjectConsideringInspection();
					window.navigateCallback(path+'/defect/c');
				}
			},
			'vclick .actionsTrigger':           'openMenu',
			'vclick .actionsMenu .changeSelectedDefects:not(.disabled)': function (e) {
				this.editDefects(e, true);
			},
			'vclick #defects-actions-menu .ui-icon-gear': function (e) {
				this.editDefects(e, false);
			},
			'vclick .actionsMenu .fixChosen:not(.disabled)': function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (user.isRestrictedSubcontractor()) {
					window.registrationRequestPopup();
					return;
				}

				if (this.checkedIds.length == 0) {
					window.alertPopup(user.translate('defects.menu.pleaseselect'));
					return;
				}
				window.confirmPopup(user.translate('defects.fixchosen')).then(_.bind(function () {
					var queue = $.Deferred().resolve();
					_.each(this.checkedIds, function (id) {
						var defect = currentDefects.get(id);
						if (defect) {
							if (user.isPrincipal() || currentDefects.elementFilter.external) {
								defect.set('principalStatus', 'accepted');
							} else if (user.isSubcontractor()) {
								defect.set('subcontractorStatus', 'fixed');
							} else {
								defect.set('status', 'fixed');
							}
							queue = queue.then(function () {
								var result = $.Deferred();
								defect.save().always(function () {
									result.resolve();
								});
								return result;
							});
						}
					}, this);
				}, this));
				this.$actionsMenu.popup('close');
			},
			'vclick .actionsMenu .sendChosen': function(e){
				this.sendChosen(e);
			},
			'vclick #defect-actions .link-defects': function(e){
				this.linkDefects(e);
			},
			'vclick #defect-actions .defects-quick-actions .sendChosen': function(e){
				setTimeout(function(){this.sendChosen(e)}.bind(this), 300);
			},

			'vclick .actionsMenu .assign, .defects-quick-actions .assign':         function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (this.checkedIds.length == 0) {
					window.alertPopup(user.translate('defects.menu.pleaseselect'));
					return;
				}
				confirmPopup(user.translate('defects.assign')).then(_.bind(function () {
					var queue = $.Deferred().resolve();
					_.each(this.checkedIds, function (id) {
						var defect = currentDefects.get(id);
						if (defect) {
							var inspections = defect.get('inspections').slice();
							inspections.push(currentInspection.id);
							defect.set('inspections', _.uniq(inspections));
							queue = queue.then(function () {
								var result = $.Deferred();
								defect.save().always(function () {
									result.resolve();
								});
								return result;
							});
						}
					}, this);
					this.$actionsMenu.popup('close');
					this.refreshSelections('deselect');
				}, this));
			},
			'vclick .actionsMenu .unassign, .defects-quick-actions .unassign':       function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (this.checkedIds.length == 0) {
					window.alertPopup(user.translate('defects.menu.pleaseselect'));
					return;
				}
				confirmPopup(user.translate('defects.unassign')).then(_.bind(function () {
					var queue = $.Deferred().resolve();
					_.each(this.checkedIds, function (id) {
						var defect = currentDefects.get(id);
						if (defect) {
							defect.set('inspections', _.without(defect.get('inspections') || [], currentInspection.id));
							queue = queue.then(function () {
								var result = $.Deferred();
								defect.save().always(function () {
									result.resolve();
								});
								return result;
							});
						}
					}, this);
					this.$actionsMenu.popup('close');
					this.refreshSelections('deselect');
				}, this));
			},
			'vclick .actionsMenu .selectAll':      function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.refreshSelections($(e.currentTarget).hasClass('deselect') ? 'deselect' : 'select');
				this.$actionsMenu.popup('close');
			},
			'change .orderBy': function (e) {
				var orderBy = $(e.currentTarget).val();
				if (this.orderBy !== orderBy) {
					this.orderBy = orderBy;
					this.refreshList({ newListContext: true });
				}
				this.$actionsMenu.popup('close');
			},
			'change .groupBy': function (e) {
				var $checkbox = $(e.currentTarget), groupBy = $checkbox.prop('checked') ? $checkbox.val() : '';
				if (this.groupBy !== groupBy) {
					this.groupBy = groupBy;
					this.$('.groupBy').not('[value=' + this.groupBy + ']').prop('checked', false).checkboxradio('refresh');
					this.refreshList({ newListContext: true });
				}
				this.$actionsMenu.popup('close');
			},
			'vclick .defects-quick-actions .defect-action-export': function (e) {
				if (user.isRestrictedSubcontractor()) {
					window.registrationRequestPopup();
					return;
				}

				if (!watchdog.isConnected()) {
					window.alertPopup(user.translate('defects.menu.onlyonline'));
					return;
				}
				$("#defects-actions-menu .defect-export-options").css('visibility', 'visible');
				$("#defects-actions-menu .defect-export-options").removeClass("hide-export-options");
				$("#defects-actions-menu .defect-export-options").addClass("show-export-options");
				if ($('body').has($('#superTestDiv')).length == 0) {
					$('body').append('<div id="superTestDiv" style="width: 100%; height: 100%; position: absolute;">');
					$('#superTestDiv, #defects #defects-actions-menu').click(function (e) {
						if ($("#defects #defects-actions-menu.defects-quick-actions .defect-export-options").get(0) != e.target && $("#defects #defects-actions-menu .defect-action-export").get(0) != e.target
							&& $("#defects #defects-actions-menu .defect-action-export label").get(0) != e.target) {
							$("#defects-actions-menu .defect-export-options").addClass("hide-export-options");
							$("#defects-actions-menu .defect-export-options").removeClass("show-export-options");
							$('#defects #defects-actions-menu').unbind('click');
							$('#superTestDiv').remove();
						}
						if($("#defects #defects-actions-menu .defects-more-actions").get(0) == e.target || $("#defects #defects-actions-menu .defects-more-actions label").get(0) == e.target) {
							$("#defects #defects-actions-menu .defect-export-options").css('visibility', 'hidden');
						}
					});
				}
			},
			'vclick #defects-actions-menu .defects-more-actions': function (e) {
				$("#defects-actions-menu .defects-more-actions-options").css('visibility', 'visible');
				$("#defects-actions-menu .defects-more-actions-options").removeClass("hide-actions");
				$("#defects-actions-menu .defects-more-actions-options").addClass("show-actions");
				if ($('body').has($('#superTestDiv')).length == 0) {
					$('body').append('<div id="superTestDiv" style="width: 100%; height: 100%; position: absolute;">');
					$('#superTestDiv, #defects #defects-actions-menu').click(function (e) {
						if ($("#defects #defects-actions-menu.defects-quick-actions .defects-more-actions-options").get(0) != e.target && $("#defects #defects-actions-menu .defects-more-actions").get(0) != e.target
							&& $("#defects #defects-actions-menu .defects-more-actions label").get(0) != e.target) {
							$("#defects-actions-menu .defects-more-actions-options").addClass("hide-actions");
							$("#defects-actions-menu .defects-more-actions-options").removeClass("show-actions");
							$('#defects #defects-actions-menu').unbind('click');
							$('#superTestDiv').remove();
						}
						if($("#defects #defects-actions-menu .defect-action-export").get(0) == e.target || $("#defects #defects-actions-menu .defect-action-export label").get(0) == e.target) {
							$("#defects #defects-actions-menu .defects-more-actions-options").css('visibility', 'hidden');
						}
					});
				}
			},
			'vclick .actionsMenu .download-pdf,.defects-quick-actions .download-pdf': function (e) {
				e.preventDefault();
				e.stopPropagation();

				this.postSelectedIds().done(_.bind(function () {
					var url =  '/onlineBauabnahme/webStatic/liste.pdf?project=' + window.encodeURIComponent(currentProject.id) + this.filterParams() + '&' + new Date().getTime();
					if (iosapp.appavailable) {
						$.mobile.loading('show');
						$.post(url + "&returnLink=true").done(function(data) {
							$.mobile.loading('hide');

							if (data && data.file) {
								iosapp.downloadAndSharePdf(window.location.origin +  "/onlineBauabnahme/webStatic/generatedAndSavedDocuments/" + data.file, data.filename);
								this.$actionsMenu.popup('close');
							} else {
								window.alertPopup('Fehler beim Erstellen des PDFs. Bitte versuchen Sie es später noch einmal.');
							}
						}.bind(this))
							.fail(function(jqXHR, textStatus, errorThrown) {
								$.mobile.loading('hide');
								if (jqXHR && jqXHR.status == 400 && jqXHR.statusText == 'Too many defects') {
									window.alertPopup('Sie haben mehr als 1000 Mängel ausgewählt, dies sind zu viele für diese Operation. Bitte wählen Sie weniger Mängel aus oder kontaktieren Sie den Support.');
								} else {
									window.alertPopup('Fehler beim Erstellen des PDFs. Bitte versuchen Sie es später noch einmal.');
								}
							}.bind(this));
					} else {
						window.open(url);
						this.$actionsMenu.popup('close');
					}
				}, this));
			},
			'vclick .actionsMenu .download-excel, .defects-quick-actions .download-excel': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.postSelectedIds().done(_.bind(function () {
					window.open('/onlineBauabnahme/api/export?project=' + window.encodeURIComponent(currentProject.id) + this.filterParams() + '&zipped=0&' + new Date().getTime() + '&fullHistory=false');
					this.$actionsMenu.popup('close');
				}, this));
			},
			'vclick .actionsMenu .download-excel, .defects-quick-actions .download-excel-fullhistory': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.postSelectedIds().done(_.bind(function () {
					window.open('/onlineBauabnahme/api/export?project=' + window.encodeURIComponent(currentProject.id) + this.filterParams() + '&zipped=0&' + new Date().getTime() + '&fullHistory=true');
					this.$actionsMenu.popup('close');
				}, this));
			},
			'vclick .actionsMenu .export, .defects-quick-actions .export': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.postSelectedIds().done(_.bind(function () {
					var url =  '/onlineBauabnahme/api/export?project=' + window.encodeURIComponent(currentProject.id) + this.filterParams() + '&zipped=1&' + new Date().getTime() + '&fullHistory=false';
					window.open(url);
					this.$actionsMenu.popup('close');
				}, this));
			},
			'vclick .actionsMenu .export, .defects-quick-actions .export-fullhistory': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.postSelectedIds().done(_.bind(function () {
					var url =  '/onlineBauabnahme/api/export?project=' + window.encodeURIComponent(currentProject.id) + this.filterParams() + '&zipped=1&' + new Date().getTime() + '&fullHistory=true';
					window.open(url);
					this.$actionsMenu.popup('close');
				}, this));
			},
			'focus #defectInspectionFilter': function () {
				this.$inspectionFilter.val('');
			},
			'focus #defectSubcontractorFilter': function () {
				this.$subcontractorFilter.val('');
			},
			'vclick .customDefectsNotifier': function () {
				this.resetCustomIdFilter();
				this.filter();
			},

			'touchstart .qrcodetrigger': 'beginqrcodescan',
			'mousedown  .qrcodetrigger': 'beginqrcodescan',
			'mousedown #automatic-subcontractor-select-input, #automatic-subcontractor-select-label': 'handleAutomaticSubcontractorSelectionButton',
			'touchstart #automatic-subcontractor-select-input, #automatic-subcontractor-select-label': 'handleAutomaticSubcontractorSelectionButton',
			'click .listheader .select-all-defects': function(e) {
				this.refreshSelections($(e.currentTarget).prop('checked') ? 'select' : 'deselect');
			},
			'vclick .actionsMenu .adjustColumns': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.$actionsMenu.popup('close').on('popupafterclose', function () {
					this.$showDefectDataPopup.show().popup('open', { transition: 'flip'});
					this.$actionsMenu.popup('close').unbind('popupafterclose');
				}.bind(this));
				var $applyChanges = this.$showDefectDataPopup.find('.ok');
				var settings = _.cloneDeep(user.get('settings'));
				var defectMetadata = this.$showDefectDataPopup.find('.metadata-columns');
				var defectCreator = defectMetadata.find('input[name="defect-creator"]');
				var defectCreationDate = defectMetadata.find('input[name="creation-date"]');
				var defectActualisationDate = defectMetadata.find('input[name="actualisation-date"]');
				var defectDeadlineDate = defectMetadata.find('input[name="deadline-date"]');
				var defectEscalationlevel = defectMetadata.find('input[name="escalationlevel"]');
				// var defectFixedDate = defectMetadata.find('input[name="fixed-date"]');
				var defectSubcontractor = defectMetadata.find('input[name="subcontractor"]');
				defectSubcontractor.parent().find('label').text(((!user.isPrincipal() || currentProject.get('showSubcontractorsToPrincipal')) ? user.translate('defects.header.subcontractor') : user.translate('defects.header.crew')));
				defectCreator.prop('checked', settings.defectMetadata.defect_show_creator).checkboxradio('refresh');
				defectCreationDate.prop('checked',settings.defectMetadata.defect_show_creation_date).checkboxradio('refresh');
				defectActualisationDate.prop('checked',settings.defectMetadata.defect_show_actualisation_date).checkboxradio('refresh');
				defectDeadlineDate.prop('checked',settings.defectMetadata.defect_show_deadline).checkboxradio('refresh');
				defectEscalationlevel.prop('checked',settings.defectMetadata.defect_show_escalationlevel).checkboxradio('refresh');
				defectSubcontractor.prop('checked',settings.defectMetadata.defect_show_subcontractor).checkboxradio('refresh');
				// defectFixedDate.prop('checked',settings.defectMetadata.defect_show_fixed_date).checkboxradio('refresh');

				if(user.isPrincipal()) defectEscalationlevel.parent().hide();
				var maxNumColumns;
				$applyChanges.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();

					this.$showDefectDataPopup.popup('close');

					settings.defectMetadata.defect_show_creator = defectCreator.prop('checked');
					settings.defectMetadata.defect_show_creation_date = defectCreationDate.prop('checked');
					settings.defectMetadata.defect_show_actualisation_date =defectActualisationDate.prop('checked');
					settings.defectMetadata.defect_show_deadline = defectDeadlineDate.prop('checked');
					settings.defectMetadata.defect_show_escalationlevel = defectEscalationlevel.prop('checked');
					settings.defectMetadata.defect_show_subcontractor = defectSubcontractor.prop('checked');
					// settings.defectMetadata.defect_show_fixed_date = defectFixedDate.prop('checked');

					user.save({ settings: settings }, { silent: true, url: '/onlineBauabnahme/api/settings' })
					this.render();
					$applyChanges.off('vclick');
				}, this));
			},
			'click .ui-li-divider': function (e) {
				var group = e.target.dataset.group;
				var open;
				if(!this.groupToggles) {
					this.groupToggles = new Map();
					open = 'false';
				} else {
					open = (this.groupToggles.get(group) && this.groupToggles.get(group) === 'false' ? 'true' : 'false');
				}
				this.groupToggles.set(group, open);
				e.target.dataset.open = open;
				this.$('.defects-list').children('[data-group="' + group + '"]').not('.ui-li-divider').toggle(open === 'true');

				var listlength = $('ul.defects-list li.ui-li-static:not(.moreRows)').length;
				if(this.prevPageSize) {
					if(listlength > this.prevPageSize) this.prevPageSize = listlength;
				} else {
					if(listlength > this.pageSize) {
						this.prevPageSize = listlength;
					} else {
						this.prevPageSize = this.pageSize;
					}
				}

				this.refreshList();
			},
			'change #filters .escalationLevelFilter input': 'filter',
			'change #filters .deadlineFilter input': 'filter',
			'change .selectedDefectsChangeDataDialog .escalationcg input': function (e) {
				var target = e.target;
				if($(target).val() === '4') {
					window.confirmPopup(user.translate('defect.escalationlevel.defect.info.terminateQuestion') + '\n\n' + user.translate('defect.escalationlevel.defect.info.terminateQuestion2'))
						.fail(function () {
							$(target).closest('div.ui-controlgroup-controls ').find('input[value=nochange]').closest('div').find('label').click();
						}.bind(this))
						.always(function () {
							this.$changeDataDialogForSelectedDefects.show().popup('open', {transition: 'flip'});
						}.bind(this))
				}
				if($(target).val() === 'next') {

					const defectTypes = currentProject.get('types');
					const relevantDefects = [];
					_.each(this.checkedIds, function (id) {
						const defect = currentDefects.get(id);
						const maxRequests = defectTypes.find(function (defectType) {
							return defectType.defectTypeId === defect.get('type');
						}).requests
						if (defect.get('requests') === maxRequests) {
							relevantDefects.push(defect.get('id').toString());
						}
					}, this);
					if(relevantDefects.length > 0) {
						const relevantDefectsString = relevantDefects.join(', ')
						window.confirmPopup(user.translate('defect.escalationlevel.defect.info.question') + '\n' + relevantDefectsString + '\n\n' + user.translate('continue') + '?')
							.fail(function () {
								$(target).closest('div.ui-controlgroup-controls ').find('input[value=nochange]').closest('div').find('label').click();
							}.bind(this))
							.always(function () {
								this.$changeDataDialogForSelectedDefects.show().popup('open', {transition: 'flip'});
							}.bind(this))
					}
				}
			},
			'vclick .reloadlist': function (e) {
				currentDefects.fetch().then(function () {
					this.refreshList();
				}.bind(this));
			},
			'click .defectExternalBox label': 'handleInternalExternalClick',
			'touchstart .defectExternalBox label': 'handleInternalExternalClick',
			'change .addFilter select': 'addFilter',
			'click .removeFilters': 'removeFilters',
			'click .hideFilters': 'hideFilters',
			'vclick .setFromTo': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.$fromToDialog.show().popup('open');
				var $buttons = this.$fromToDialog.find('button');
				$buttons.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					if ($(e.currentTarget).hasClass('ok')) {
						this.$setFromToButton.text(user.translate('filter.creationdate.button') + ': ' + (this.from ? this.from : '-') + ' | ' + (this.to ? this.to : '-'));
						this.filter();
					} else if($(e.currentTarget).hasClass('cancel')) {
						var text = this.$setFromToButton.text();
						var from = text.split(':')[1].trim().split('|')[0].trim()
						var to = text.split(':')[1].trim().split('|')[1].trim()
						this.from = from;
						this.to = to;
						this.$fromToDialog.find('input.from').val(from);
						this.$fromToDialog.find('input.to').val(to);
					}
					$buttons.off('vclick');
					this.$fromToDialog.popup('close');
				}, this));
			},
			'change .fromToDialog input': function (e) {
				var changed = $(e.target);
				var from = this.$fromToDialog.find('input.from');
				var to = this.$fromToDialog.find('input.to');
				if(changed.hasClass('from')) {
					to.attr('min', changed.val());
					if((to.val() === '' || to.val() < changed.val()) && changed.val() !== '') to.val(changed.val());
				} else {
					from.attr('max', changed.val());
					if((from.val() === '' || from.val() > changed.val()) && changed.val() !== '') from.val(changed.val());
				}
				this.from = from.val();
				this.to = to.val();
			},
			'vclick .defect-entry': function (e) {
				e.stopPropagation(); e.preventDefault();
				var target = $(e.currentTarget);
				var defectId = target.attr('data-id');
				if(!!defectId) {
					var path = backboneReactCommunicationUtils.getCustomerProjectConsideringInspection();
					window.navigateCallback(path+'/defect/n'+defectId);
				}
			},

		},

		handleInternalExternalClick: function (e) {
			e.stopPropagation(); e.preventDefault();
			var target = $(e.target).closest('div');
			target.find('label').removeClass('ui-icon-check ui-icon-delete');
			var status = target.find('input').prop('multichecked');
			if(!status || status === false || status === 0) {
				target.find('input').prop('multichecked', 1);
				target.find('label').addClass('ui-icon-check');
				target.find('label').text(user.translate('defects.filter.external.external'));
			} else if (status === 1) {
				target.find('input').prop('multichecked', 2);
				target.find('label').addClass('ui-icon-delete');
				target.find('label').text(user.translate('defects.filter.external.internal'));
			} else {
				target.find('input').prop('multichecked', 0);
				target.find('label').text(user.translate('defects.filter.external.both'));
			}
			this.filter(e);
		},

		handleAutomaticSubcontractorSelectionButton: function (e) {
			if(e) {
				e.preventDefault();
				e.stopPropagation();
			}
			var checked = this.$automaticAnSelection[0].dataset.cacheval === 'true';
			var anInput = this.setSubcontractorForSelectedDefects;
			anInput.prop('disabled', checked).parent().toggleClass('disabled', checked).toggleClass('anf', checked);
			if(checked) this.$('#automatic-subcontractor-select-label').css('border', '2px solid orange');
			if(!checked) this.$('#automatic-subcontractor-select-label').css('border', '');
		},

		changeMode: function (mode) {
			this.filterMode = mode;

			currentDefects.detailFilter = null;
			this.$actionsMenu.popup('close');

			this.resize();
			if(!!this.toResetCustomerIdFilter) {
				this.resetCustomIdFilter();
			}
			this.afterChangeFilterMode();
			currentDefects.detailFilter = null;
			this.refreshDisplayOptions();
			this.refreshList({ newListContext: true });
			this.renderExtendedFilter();
		},

		isActionsMenuOpen: function () {
			return $('div.ui-popup-container.ui-popup-active').length > 0;
		},

		openMenu:  function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('open', { transition: 'flip', positionTo: $(e.currentTarget) });
		},

		triggerActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('open', { transition: 'flip', positionTo: $('.actionsTrigger') });
		},

		closeActionsMenu: function (e) {
			e.preventDefault();
			e.stopPropagation();
			this.$actionsMenu.popup('close', { transition: 'flip', positionTo: $('.actionsTrigger') });
		},

		beginqrcodescan: function(e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			if (iosapp.appavailable && iosapp.functionavailable('qrcode')) { //shouldn't be necessary (element is hidden if we are not in the ios app) but safety first
				iosapp.scanQrCode();
			} else if (navigator.userAgent.match(/(android)/i) != null && typeof androidapp !== 'undefined') {
				androidapp.scanQRCode();
			}
		},
		initialize: function (options) {
			this.router = options.router;
			this.pagevariant = options.pagevariant || 'defects';
			this.resize = _.bind(this.resize, this);
			// window.openMenu = _.bind(this.openMenu, this);

			Backbone.Events.on("selectAllDefects", _.bind(function(){
				if(this.pagevariant == "defects") {
					this.refreshSelections('select');
					this.setDeadline();
				}
			}, this));

			this.$el.on('pagebeforeshow', _.bind(function () {
				this.pageVisible = true;
			}, this));
			this.$el.on('pagebeforehide', _.bind(function () {
				this.pageVisible = false;
			}, this));

			this.listenTo(currentDefects, 'add remove change update reset', _.throttle(function () {
				_.defer(function (self) {
					if (self.pageVisible) {
						var scroll = self.$list.scrollTop();
						self.refreshList();
						self.$list.scrollTop(scroll);
					}
				}, this);
			}, 1000));

			this.$el.html(this.template({ t: user.translate, pagevariant: this.pagevariant })).appendTo($.mobile.pageContainer);
			this.$el.find('.twocolumns, .threecolumns').contents().filter(function () {
				return this.nodeType === 3;
			}).remove();

			this.reducedDefectsBanner = new ReducedDefectsBanner();

			this.$header = this.$('[data-role=header]');
			this.$content = this.$('[data-role=content]');
			this.$reducedDefectsBanner = this.$('.reducedDefectsBanner');
			console.log('$reducedDefectsBanner', this.$reducedDefectsBanner);
			this.$heading = this.$('.heading');
			this.$previousPage = this.$('.previousPage');
			this.$newDefect = this.$('.newDefect');
			this.$subcontractorFilter = this.$('#defectSubcontractorFilter');
			this.$inspectionFilter = this.$('#defectInspectionFilter');
			this.$statusFilter = this.$('#defectStatusFilter');
			this.$defectSearchFilter = this.$('#defectSearchFilter');
			this.$defectsLocation = this.$('#defectsLocation');
			this.$defectTypeFilter = this.$('#defectTypeFilter');
			this.$list = this.$('.list');
			this.$actionsMenu = this.$('.actionsMenu');
			this.$locationFilter = this.$('#locationFilter');
			this.locationText = this.$('.search-location input[type="text"]');
			this.$searchLocationField = this.$('.search-location input[type="text"]');
			this.$filters = this.$('#filters');
			this.$selectAll = this.$actionsMenu.find('.selectAll');
			this.$deadlineDialog = this.$('.dateDialog');
			this.$deadlineChooserSelectedDefects = this.$('.deadlineChooserSelectedDefects');
			this.$defectTypeChooser = this.$('.defectTypeChooser');
			this.$textTypeChooser = this.$('.textTypeChooser');
			this.$qrcodetrigger = this.$('.qrcodetrigger');
			this.$changeDataDialogForSelectedDefects = this.$('.selectedDefectsChangeDataDialog');
			this.$progressSliderForSelectedDefects = this.$('.updateProgress');
			this.setSubcontractorForSelectedDefects = this.$('.defects-setsubcontractor .select-subcontractor');
			this.$customDefectsNotifier = this.$('.customDefectsNotifier');
			this.$customDefectsNotifier.toggle(false);
			this.$defectsCount = this.$('.defects-count');
			this.defectIDCustomList = [];
			this.areAllDefectsSelected = false;
			this.$detailsFilterHeader = this.$('#detailsFilterHeader');


			this.$automaticAnSelection = this.$('#automatic-subcontractor-select-input').checkboxradio()
			this.$automaticAnSelectionDiv = this.$('#automatic-subcontractor-select');

			this.$showDefectDataPopup = this.$('.showDefectData');
			this.$defectListHeader = this.$('.listheader');
			this.$projectTitle = this.$('.listheader .project-name');

			this.$selectedDefectsEscalationLevelChooser = this.$('#escalationLevelChooserSelectedDefects');
			this.$escalationLevelChooserRadio1 = this.$selectedDefectsEscalationLevelChooser.find('input[value=1]').checkboxradio();
			this.$escalationLevelChooserRadio2 = this.$selectedDefectsEscalationLevelChooser.find('input[value=2]').checkboxradio();
			this.$escalationLevelChooserRadio3 = this.$selectedDefectsEscalationLevelChooser.find('input[value=3]').checkboxradio();
			this.$escalationLevelChooserRadio4 = this.$selectedDefectsEscalationLevelChooser.find('input[value=4]').checkboxradio();
			this.$escalationLevelChooserRadioNoChange = this.$selectedDefectsEscalationLevelChooser.find('input[value="nochange"]').checkboxradio();
			this.$escalationLevelChooserRadioNext = this.$selectedDefectsEscalationLevelChooser.find('input[value="next"]').checkboxradio();

			this.$requestsFilter = this.$('#defectRequestsFilter');

			this.$defectExternalFilterCheckbox = this.$('.defectExternalBox input');
			this.$defectExternalFilterLabel = this.$('.defectExternalBox label');
			this.$defectExternalFilterLabel.removeClass('ui-checkbox-on').addClass('ui-checkbox-off')

			this.$addFilterSelect = this.$('.addFilter');

			this.$fromToDialog = this.$('.fromToDialog');
			this.$setFromToButton = this.$('.setFromTo');

			window.addEventListener('orientationchange', this.handleScreenRotation.bind(this));

			if (iosapp.appavailable && iosapp.functionavailable('qrcode')) {
				iosapp.addQrCodeHandler(_.bind(function (code) {
					if (code && this.pageVisible) {
						this.filter(null, code);
					}
				}, this));
			} else if (navigator.userAgent.match(/(android)/i) != null && typeof androidapp != "undefined") {
				iosapp.addQrCodeHandler(_.bind(function (code) {
					if (code && this.pageVisible) {
						this.filter(null, code);
					}
				}, this));
			} else {
				this.$qrcodetrigger.remove();
			}

			new Autocomplete({
				input: this.$subcontractorFilter,
				lookup: function (str) {
					var result = [];
					if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
						result.push(user.translate('defects.filter.anycrew'));
						Array.prototype.push.apply(result, currentProject.get('crews'));
					} else {
						result.push(user.translate('defects.filter.anysubcontractor'));
						if (!user.isSubcontractor()) {
							result.push(user.translate('defects.filter.emptysubcontractor'));
						}
						Array.prototype.push.apply(result, completions.subcontractorLookup(str));
					}
					return result;
				},
				toggleButton: true
			}).render();

			this.escalationFilters = [['NotSent', user.translate('defect.menu.noEscalationlevel')], ['one', user.translate('defect.escalationlevel.dialog.firstlevel')],
				['two', user.translate('defect.escalationlevel.dialog.secondlevel')], ['three', user.translate('defect.escalationlevel.dialog.thirdlevel')]]
			this.terminationFilter = [['X', user.translate('defects.filter.escalationlevel.terminated')]]
			this.deadlineFilters = [['Expired', user.translate('defects.filter.deadline.expired')], ['NotExpired', user.translate('defects.filter.deadline.notexpired')],
				['NoDate', user.translate('defects.filter.deadline.nodeadline')], ['Today', user.translate('defects.filter.deadline.today')]]
			this.multiselect = new MultiSelect({
				input: this.$requestsFilter,
				items: this.escalationFilters.concat(this.terminationFilter).concat(this.deadlineFilters),
				escalationFilters: currentDefects.elementFilter.escalationLevels,
				deadlineFilters: currentDefects.elementFilter.deadlineFilters,
				callback: function () {
					this.$requestsFilter.children().remove();
					this.$requestsFilter.html('');
					if(this.multiselect.selectedItems && this.multiselect.selectedItems.length > 0) {
						_.each(this.multiselect.selectedItems, function (item) {
							var check = Array.isArray(item) ? item[0] : item;
							this.$requestsFilter.append($('<div class="' + check.toLowerCase() + '">'));
						}.bind(this));
					} else {
						this.$requestsFilter.append($('<span style="opacity: 0.5">').text('— ' + user.translate('defects.filter.empty') + ' —'))
					}
					this.filter();
				}.bind(this),
				toggleButton: true
			}).render();

			new Autocomplete({
				input: this.setSubcontractorForSelectedDefects,
				lookup: function (str) {
					var result;
					if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
						result = currentProject.get('crews');
					} else {
						result = completions.subcontractorLookup(str);
					}
					if (user.isPrincipal()) {
						result = _.flatten([user.translate('defect.primecontractor'), result], true);
					}
					return result;
				},
				toggleButton: true
			}).render();

			new Autocomplete({
				input: this.$inspectionFilter,
				lookup: _.bind(function (str) {
					var result = [];
					if (str === user.translate('defects.filter.anyinspection')) {
						str = '';
					}
					if (currentDefects.elementFilter.inspection !== null) {
						result.push(user.translate('defects.filter.anyinspection'));
					}
					if (!user.isSubcontractor()) {
						result.push(user.translate('defects.filter.emptyinspection'));
					}
					if (this.inspectionId(str)) {
						str = '';
					}
					Array.prototype.push.apply(result, completions.inspectionLookup(str, currentDefects.elementFilter.external));
					return result;
				}, this),
				toggleButton: true
			}).render();

			new Autocomplete({
				input: this.$searchLocationField,
				searchLocation: true,

				lookup: function (input){
					if (!input || input.length < 2) {
						return [];
					}
					var inputRegex = new RegExp(input.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
					var highlighted;

					var styleInput = '<b style="background-color:#F2C078; border-radius: 5px;">' + input + '</b>';

					var foundLocationForLength = 0;
					var allLocations = [];
					var originLocations = [];

					var selectedLocations =  $('#locationFilter .ui-select').find("span");
					for (var i = 0; i < selectedLocations.length; i++){
						originLocations[i] = selectedLocations[i].textContent;
					}

					var currentSelectedLocations = _.clone(originLocations);

					allLocations = completions.locationLookup(currentProject.get('unitTree'), allLocations);

					var filteredLocations = [];
					filteredLocations.push("Suchen nach Orten mit " + " " + styleInput);

					function checkForCurrentLocation (currentSelectedLocations, theLocation) {
						if(currentSelectedLocations.length < foundLocationForLength){
							return false;
						}
						for(var iCurr = 0; iCurr < currentSelectedLocations.length; iCurr++){// checks if the given location matches the locations that are specified by the user
							if((iCurr != 0 && theLocation.indexOf(' ' + currentSelectedLocations[iCurr] + ' ') === -1)
								|| theLocation.indexOf(currentSelectedLocations[iCurr] + ' ') === -1) {
								return false;
							}
						}
						if(currentSelectedLocations.length > foundLocationForLength){//better match has been found
							foundLocationForLength = currentSelectedLocations.length;
							filteredLocations = [];
							filteredLocations.push("Suchen nach Orten mit " + " " + styleInput);

						}
						return true;
					}

					for (var iAll = 0; iAll < allLocations.length; iAll++){
						if(allLocations[iAll].search(inputRegex) === -1){
							continue;
						}
						while(!_.isEmpty(currentSelectedLocations)){ //the locations that are currently selected by the user
							if(checkForCurrentLocation(currentSelectedLocations, allLocations[iAll])){
								highlighted = allLocations[iAll].replace(inputRegex, styleInput);
								if (filteredLocations.length < 100) //no need to present more locations since the input is not specific enough
									filteredLocations.push(highlighted);
								break;
							}
							currentSelectedLocations.pop();
						}
						currentSelectedLocations = _.clone(originLocations);
					}

					if(filteredLocations.length == 1){

						for (var iAll = 0; iAll < allLocations.length && filteredLocations.length < 100; iAll++) { //no need to present more locations since the input is not specific enough
							if(allLocations[iAll].search(inputRegex) > -1){
								highlighted = allLocations[iAll].replace(inputRegex, styleInput);
								filteredLocations.push(highlighted);
							}
						}

						if(filteredLocations.length == 1) {
							filteredLocations.push(user.translate('defects.location.noresults'));
						}

					}

					var searchLocations = [];
					if (!_.isEmpty(originLocations)) {

						var locationPath = "";
						searchLocations[0] = "Suchen nach Mängeln mit " + " " + styleInput + " in";
						searchLocations[1] = user.translate('defects.location.all');

						for (var i = 0; i < originLocations.length - 1; i++){

							locationPath += originLocations[i] + " > ";
							searchLocations[i+2] = (locationPath);
						}
						for (var i = searchLocations.length - 1; i >= 0; i--) {
							filteredLocations.unshift(searchLocations[i]);
						}
					}
					var wrapper = [];
					wrapper.push(filteredLocations);
					wrapper.push(input);
					wrapper.push(originLocations.length + 2); // 1 for the searchAllLocations option


					return wrapper;

				}.bind(this)

			}).render();

			if (!user.isSubcontractor() && (user.isReadonly() || !user.isPrincipal())) {
				this.$('.fixChosen').closest('li').remove();
			}

			if (user.isSubcontractor() || user.isReadonly()) {
				this.$('.changeSelectedDefects').closest('li').remove();
				if (!user.isSubcontractor()) this.$('#defects-actions-menu .ui-icon-gear').remove();
				//this.$('.assign'     ).closest('li').remove();
				//this.$('.unassign'   ).closest('li').remove();
			}

			if (user.isSubcontractor() || user.isReadonly() || user.isPrincipal()){
				this.$('.sendChosen').closest('li').remove();
				$('#defects-actions-menu.defects-quick-actions .sendChosen').remove();
			}

			this.$actionsMenu.find('.orderBy').checkboxradio();
			this.$actionsMenu.find('.groupBy').checkboxradio();
			this.$actionsMenu.find('input[name=filterMode]').checkboxradio();
			this.refreshDisplayOptions();

			this.$list.scroll(_.bind(function () {
				if (this.$moreRows && this.$moreRows.offset().top + 48 < this.$list.offset().top + this.$list.height()) {
					this.triggerMore();
				}
			}, this));

			this.$el.on('pageshow', _.bind(function () {
				this.resize();

				if (this.firstPageShow) {
					this.firstPageShow = false;
					this.refreshDisplayOptions();
				}
			}, this));
			$(window).on('throttledresize', this.resize);
			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				if (!this.$el.is(':visible')) {
					return;
				}
				this.keepSelection = true;
				this.render();
			}, this));

		},

		render: function () {
			this.reducedDefectsBanner.renderReducedDefectsBanner(this.$reducedDefectsBanner, () => { this.render(); });

			$('.extended-filter').off('click');
			$('.extended-filter').on('click', function (e) {
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/filter');
			}.bind(this));
			$('.defects-only-favourite').off('click');
			$('.defects-only-favourite').on('click', function (e) {
				this.toggleFavouriteDefects(e);
			}.bind(this));
			$('.actionsTrigger').off('click');
			$('.actionsTrigger').on('click', function (e) {
				this.openMenu(e);
			}.bind(this));
			$('.newDefect').off('click');
			$('.newDefect').on('click', function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (currentInspection && currentInspection.get('id') && $(e.target).data('alternatetype') && $(e.target).data('alternatetype').length > 0) {
					window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defect/c'+ $(e.target).data('alternatetype'));
				} else {
					var path = backboneReactCommunicationUtils.getCustomerProjectConsideringInspection();
					window.navigateCallback(path+'/defect/c');
				}
			}.bind(this));
			$('.previousPage').off('click');
			$('.previousPage').on('click',  function (e) {
				var customerProject = backboneReactCommunicationUtils.getCustomerProject();
				var customerProjectWithInspection = backboneReactCommunicationUtils.getCustomerProjectConsideringInspection();
				var pagePath = '';
				switch (this.previousPage) {
					case 'inspection':       pagePath = customerProjectWithInspection;  break;
					case 'inspections':      pagePath = customerProject+'/inspections'; break;
					case 'location':         pagePath = customerProjectWithInspection + '/location'; break;
					case 'principal-bundle': pagePath = customerProjectWithInspection + '/principalBundle'; break;
					default:                 pagePath = customerProject; break;
				}
				window.navigateCallback(pagePath);
			}.bind(this));
			var oldCheckedIds = this.checkedIds;
			var categories = currentProject.get('categories');
			var defectTypes = currentProject.get('types');
			_.forEach(defectTypes, function (type) {
				if (this.$defectTypeFilter.find('option[value="' + type.defectTypeId + '"]').length === 0) {
					this.$defectTypeFilter.append($('<option value="' + type.defectTypeId + '">').text(type.content + ' (' + user.translate(type.label) + ')'))
				}
			}.bind(this));

			this.$defectTypeFilter.trigger('create');
			this.renderExtendedFilter();
			if (currentDefects.detailFilter) {
				this.$detailsFilterHeader.show();
				this.renderExtendedFilter();
			} else {
				this.$detailsFilterHeader.hide();
			}

			var previousLabel;
			switch (this.previousPage) {
				case 'inspection':       previousLabel = user.translate('defects.backtoinspection');  break;
				case 'inspections':      previousLabel = user.translate('defects.backtoinspections'); break;
				case 'location':         previousLabel = user.translate('defects.backtodefects'); break;
				case 'principal-bundle': previousLabel = user.translate('defects.backtoprincipal'); break;
				default:                 previousLabel = user.translate('defects.backtoproject'); break;
			}
			$('.previousPage').text(previousLabel);

			this.$heading.text(user.translate(currentDefects.elementFilter.external ? 'sidepanel.principal' : 'defects.title'));
			//this.$('.setDeadline').closest('li').toggle(!currentDefects.elementFilter.external);

			var costType = currentProject.get('costType'),
				nocost = !costType || user.isSubcontractor();
			this.$('option[value=cost0], option[value=costto200], option[value=costto1000], option[value=costfrom1000], option[value=costfrom5000]')
				.prop('disabled', nocost || costType === 'GLOBAL');
			this.$('option[value=cost1000]').prop('disabled', nocost || costType !== 'GLOBAL');
			this.$('option[value=costnone]').prop('disabled', nocost).text(costType === 'GLOBAL' ? user.translate('defects.filter.costnonsignificant') : user.translate('defects.filter.costnone'));
			this.$('option[value=agcostnone]').prop('disabled', nocost || costType !== 'SEPARATE');

			this.$('.onlyshow-subcontractor').prop('disabled', !user.isSubcontractor());
			this.$('.onlyshow-principal'    ).prop('disabled', !user.isPrincipal());
			this.$('.noshow-subcontractor'  ).prop('disabled', user.isSubcontractor());
			this.$('.noshow-principal'      ).prop('disabled', user.isPrincipal());

			this.$('.current-inspection-name').toggle(!currentInspection.isNew());

			if (!currentInspection.isNew()){
				$('.current-inspection-name').text(currentInspection.get('date') + ", " + currentInspection.get('title'));
				$('#defects-actions-menu .inspection-title').css({
					"display": "block",
					"visibility": "visible"
				});
				$('#defects-actions-menu .inspection-title > p').text(currentInspection.get('date') + ", " + currentInspection.get('title'));
				$('#defects-actions-menu .ui-icon-mail').css("display", "none");
				$('#defects-actions-menu .defects-more-actions').css("display","block");
				$('#defects-actions-menu .action').css("margin", "2px 2px 6px 2px");
			} else {
				$('#defects-actions-menu .inspection-title').css({
					"visibility": "hidden",
					"display": "none"
				});
				$('#defects-actions-menu .defects-more-actions').css("display","none");
				$('#defects-actions-menu .ui-icon-mail').css("display", "block");
				$('#defects-actions-menu .action').css("margin", "2px 2px 2px 2px");
			}

			this.$('.assign').closest('li').toggle(/*!currentDefects.elementFilter.external && */ !currentInspection.isNew());
			this.$('.unassign').closest('li').toggle(/*!currentDefects.elementFilter.external && */ !currentInspection.isNew());
			//this.$('.divider1').toggle(!currentDefects.elementFilter.external);

			//this.$('.fixChosen, .setDeadline, .sendChosen').toggleClass('ui-disabled', !watchdog.isConnected());
			if (user.isSubcontractor()) {
				this.$('.extended-filter').prev().remove(); // list-divider
				this.$('.extended-filter').remove();
			}

			this.$actionsMenu.find('.defects-order-by-cost-label')
				.text(
					costType === 'GLOBAL'
						? user.translate('defects.menu.orderbycost.global')
						: costType === 'SPECIFIC'
							? user.translate('defects.menu.orderbycost')
							: currentDefects.elementFilter.external
								? user.translate('defects.menu.orderbycost.ag')
								: user.translate('defects.menu.orderbycost.an')
				)
				.parent().toggle(!nocost);

			this.$actionsMenu.find('input[name=filterMode][value=' + this.filterMode + ']').trigger('change');
			this.$qrcodetrigger.toggle(this.filterMode === 'filter' && !_.isEmpty(currentProject.get('unitTree')));
			this.refreshDisplayOptions();

			this.renderUpdateDialog();
			this.afterChangeFilterMode();

			this.refreshList();
			this.refreshLocation();
			this.refreshFavouriteFilter(currentDefects.elementFilter.favourite, true);
			if(this.id === 'inspection' && !!currentInspection && window.localStorage && window.localStorage.fromSidePanel){
				currentDefects.elementFilter = currentDefects.freshFilter();
				currentDefects.elementFilter.inspection = currentInspection.id;
			}
			this.refreshSubcontractorFilterList(currentDefects.elementFilter.subcontractor);
			this.refreshInspectionFilterList(currentDefects.elementFilter.inspection);
			this.refreshTypeFilterList(currentDefects.elementFilter.type);
			this.refreshStatusFilterList(this.getCombinedStatus(currentDefects.elementFilter));
			this.refreshEscalationDeadlineLevelFilter(currentDefects.elementFilter.escalationLevels, currentDefects.elementFilter.deadlineFilters);
			this.refreshInternalExternalFilter();
			this.refreshCreationDateFilter(currentDefects.elementFilter.creationDateFilter);
			this.filter();


			if(this.keepSelection && oldCheckedIds !== null && oldCheckedIds.length > 0) {
				this.checkedIds = oldCheckedIds;
				this.refreshSelections();
			} else {
				this.refreshSelections('deselect');
			}
			this.keepSelection = false;
			oldCheckedIds = null;

			$('.defects-quick-actions').removeClass("active-menu");

			$("#defects-actions-menu .defect-export-options").css('visibility', 'hidden');
			$("#defects-actions-menu .defects-more-actions-options").css('visibility', 'hidden');
			$('#defects-actions-menu label').each(function (e) {
				$(this).parent().width;
				$(this).width();
			})

			if(iosapp.androidappavailable || iosapp.windowsappavailable || iosapp.appavailable) {
				this.$('.download-excel, .download-excel-fullhistory, .export, .export-fullhistory').remove();

			}

			if(!currentProject.get('chooseSubAutomatically')) this.$automaticAnSelectionDiv.remove();

			if(user.isReadonly()) $('#defects-actions-menu div.defects-more-actions').remove();

			this.handleFilterSmallScreen();

			this.$filters.toggleClass('forPrinc', user.isPrincipal());

			if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
				this.$subcontractorFilter.attr('placeholder', user.translate('defects.filter.anycrew'));
			}

			return this;
		},

		handleScreenRotation: function () {
			this.handleFilterSmallScreen();
			this.$list.resize();
		},

		resetSmallScreenFilters: function () {
			var filters = ['location', 'externalinternal', 'subcontractor', 'inspection', 'status', 'type', 'requests'];
			_.each(filters, function (e) {
				this.$('div[data-filter-title="' + e + '"]').attr('shown', 'true').show().css('display', 'block').appendTo(this.$filters.children('div.ui-controlgroup-controls'));
			}.bind(this));
			this.$addFilterSelect.find('select').children('option').show();
			this.$addFilterSelect.hide().attr('shown', 'false');
			this.$addFilterSelect.find('.hideFilters, .removeFilters').hide();
			this.smallScreenFiltersWereShown = false;
		},

		handleFilterSmallScreen: function () {
			if(user.isPrincipal() || user.isSubcontractor()) this.$addFilterSelect.find('select').children('option[value="externalinternal"], option[value="requests"]').hide();
			if($(window).width() < 850 && this.$addFilterSelect.attr('shown') !== 'true') {
				this.smallScreenFiltersWereShown = true;
				this.$filters.children('div').children('div').hide();
				this.$addFilterSelect.show().attr('shown', 'true');
				this.$addFilterSelect.find('.hideFilters, .removeFilters').hide();
				this.$addFilterSelect.find('.hideFilters').addClass('ui-btn-icon-notext ui-icon-carat-d');
				this.$addFilterSelect.find('div.ui-select').addClass('borderRight');
			} else if($(window).width() >= 850) {
				if(this.smallScreenFiltersWereShown) {
					this.resetSmallScreenFilters();
				} else {
					this.$addFilterSelect.hide().attr('shown', 'false');
				}
			}
		},

		addFilter: function (e) {
			if(e.target.value !== '') {
				this.$addFilterSelect.find('div.ui-select').removeClass('borderRight');
				var value = e.target.value;
				$(e.target).val('').change();
				$(e.target).children('option[value="'+value+'"]').hide();
				this.$('div[data-filter-title="' + value + '"]').attr('shown', 'true').show().css('display', 'inline-block').appendTo(this.$filters.children('div.ui-controlgroup-controls'));
				if(this.$('div[data-filter-title][shown="true"]').length > 0) {
					this.$addFilterSelect.find('.hideFilters, .removeFilters').show();
					this.hideFilters(this.$('.hideFilters'), true);
				}
			}
			this.resize();
		},

		removeFilters: function (e) {
			this.$addFilterSelect.find('div.ui-select').addClass('borderRight');
			this.$addFilterSelect.attr('shown', 'false');
			this.$addFilterSelect.find('select').val('').change();
			this.$addFilterSelect.find('select').children('option').show();
			this.$('div[data-filter-title]').attr('shown', 'false');
			this.resetFilters();
			currentDefects.resetFilter();
			this.filter();
			this.handleFilterSmallScreen();
			this.resize();
			this.$('.hideFilters').removeClass('plus').addClass('minus').text('-');

			if(this.$('div[data-filter-title][shown="true"]').length === 0) this.$addFilterSelect.find('div.ui-controlgroup-controls ').children('.hideFilters, .removeFilters').hide();
		},

		hideFilters: function (e, alwaysOpen) {
			e = e.target ? $(e.target) : e;
			if(e.hasClass('minus') && !alwaysOpen) {
				if(this.$('div[data-filter-title][shown="true"]').length === 0) return;
				e.removeClass('minus').addClass('plus');
				e.text('+')
				this.$addFilterSelect.attr('shown', 'false');
				this.$addFilterSelect.find('select').val('').change();
				this.$addFilterSelect.find('select').children('option').show();
				if(user.isPrincipal() || user.isSubcontractor()) this.$addFilterSelect.find('select').children('option[value="externalinternal"], option[value="requests"]').hide();
				this.$('div[data-filter-title][shown="true"]').attr('shown', 'openbefore').hide();
				// this.handleFilterSmallScreen();
			} else {
				e.removeClass('plus').addClass('minus');
				e.text('-')
				var list = this.$('div[data-filter-title][shown="openbefore"]');
				_.each(list, function (e) {
					var value = $(e).data('filter-title');
					this.$addFilterSelect.find('select').children('option[value="' + value + '"]').hide();
					this.$('div[data-filter-title="' + value + '"]').attr('shown', 'true').show().css('display', 'inline-block');
				}.bind(this));
			}
			this.resize();
		},

		resetFilters: function () {
			this.refreshInternalExternalFilter();
			this.refreshEscalationDeadlineLevelFilter();
			this.refreshTypeFilterList()
			this.refreshFavouriteFilter()
			this.refreshInspectionFilterList()
			this.refreshSubcontractorFilterList()
			this.refreshLocation()
			this.refreshStatusFilterList()
			this.refreshCreationDateFilter();
		},

		refreshCreationDateFilter: function (filter) {
			this.from = ((filter && filter.from) ? moment(filter.from).format('YYYY-MM-DD') : '');
			this.to = ((filter && filter.to) ? moment(filter.to).format('YYYY-MM-DD') : '');
			this.$fromToDialog.find('input.from').val(this.from).attr('max', this.to);
			this.$fromToDialog.find('input.to').val(this.to).attr('min', this.from);
			this.$setFromToButton.text(user.translate('filter.creationdate.button') + ': ' + (this.from ? this.from : '-') + ' | ' + (this.to ? this.to : '-'));
		},

		refreshInternalExternalFilter: function () {
			var target = this.$('.defectExternalBox');
			target.find('label').removeClass('ui-icon-check ui-icon-delete');
			target.find('input').prop('multichecked', 0);
			target.find('label').text(user.translate('defects.filter.external.both'));
			if(user.isPrincipal() || user.isSubcontractor()) {
				target.hide();
			}
		},

		refreshEscalationDeadlineLevelFilter: function (efilters, dfilters) {
			var filter = [];
			_.each(efilters, function (f) {
				_.each(this.escalationFilters, function (ef) {
					if(ef[0].toLowerCase() === f.toLowerCase()) filter.push([ef[0], ef[1]]);
				}.bind(this))
				_.each(this.terminationFilter, function (tf) {
					if(tf[0].toLowerCase() === f.toLowerCase()) filter.push([tf[0], tf[1]]);
				}.bind(this))
			}.bind(this))
			_.each(dfilters, function (f) {
				_.each(this.deadlineFilters, function (df) {
					if(df[0].toLowerCase() === f.toLowerCase()) filter.push([df[0], df[1]]);
				}.bind(this))
			}.bind(this))
			this.multiselect.selectedItems = filter;
			this.$requestsFilter.children().remove();
			this.$requestsFilter.html('');
			if(this.multiselect.selectedItems && this.multiselect.selectedItems.length > 0) {
				_.each(this.multiselect.selectedItems, function (item) {
					var check = Array.isArray(item) ? item[0] : item;
					this.$requestsFilter.append($('<div class="' + check.toLowerCase() + '">'));
				}.bind(this));
			} else {
				this.$requestsFilter.append($('<span style="opacity: 0.5">').text('— ' + user.translate('defects.filter.empty') + ' —'))
			}
			if(user.isPrincipal() || user.isSubcontractor()) {
				this.$requestsFilter.parent().hide();
			}
		},

		resetCustomIdFilter: function () {
			this.defectIDCustomList = [];
		},

		renderUpdateDialog: function() {
			if(this.$('#slider').length === 0) {
				$('<input>').appendTo(this.$progressSliderForSelectedDefects).attr({
					'name': 'slider',
					'id': 'slider',
					'data-highlight': 'true',
					'min': '0',
					'max': '100',
					'value': '0',
					'type': 'range'
				}).slider({
					create: function () {
						$(this).parent().find('input').hide();
						$(this).parent().find('input').css('margin-left', '-9999px'); // Fix for some FF versions
						$(this).parent().find('.ui-slider-track').css('margin', '0 10px 0 10px').css('pointer-events', 'none').css('background-color', 'transparent');
						$(this).parent().find('.ui-slider-handle').remove();
					}
				}).slider("refresh");
			}
		},

		renderExtendedFilter: function() {

			if (currentDefects.detailFilter) {
				this.$detailsFilterHeader.empty();
				this.$detailsFilterHeader.show();
				this.$detailsFilterHeader.append($("<div class='detailsFilterLabel'><span>" + user.translate('defects.extendedfilter.title') + "</span></div>"));

				this.$detailsFilterHeader.append($('<button class="resetDetailsFilter ui-btn ui-btn-inline ui-btn-icon-left ui-icon-back ui-corner-all">Reset</button>').bind( "vclick", function() {
					this.changeMode("filter");
				}.bind(this)));


				if (currentDefects.detailFilter.external != "all" && currentDefects.detailFilter.external != "none") {
					if (currentDefects.detailFilter.external[0] == "true") {
						this.$detailsFilterHeader.append($("<button class='filterElement notInteractable ui-btn '>" + user.translate('filter.external.true') + "</button>"));
					} else {
						this.$detailsFilterHeader.append($("<button class='filterElement notInteractable ui-btn '>" + user.translate('filter.external.false') + " </button>"));
					}

				}


				if (currentDefects.detailFilter.type != "all" && currentDefects.detailFilter.type != "none") {
					this.$detailsFilterHeader.append($("<button class='filterElement filterElementType ui-btn'>" + user.translate('defects.extendedfilter.defecttypes') + "</button>").bind( "vclick", function() {
						var listedDefectTypes = "";
						var projectDefectTypes = currentProject.get('types');
						for (var i = 0; i < currentDefects.detailFilter.type.length; i++) {

							var defectType = _.find(projectDefectTypes, function (t) {
								return t.defectTypeId == currentDefects.detailFilter.type[i];
							});

							if (defectType) {
								listedDefectTypes += user.translate('' + defectType.label) + "\n";
							}
						}

						alertPopup(listedDefectTypes, user.translate('defects.extendedfilter.defecttypes'));
					}.bind(this)));
				}

				if (currentDefects.detailFilter.locations != "all") {
					this.$detailsFilterHeader.append($("<button class='filterElement ui-btn'>" + user.translate('defects.extendedfilter.locations') + "</button>").bind( "vclick", function() {
						var listedDefectLocations = "";
						var locationTitles = completions.locationTitleLookup(currentProject.get('unitTree'), currentDefects.detailFilter.locations, []);
						for(var i = 0; i < locationTitles.length; i++) {
							listedDefectLocations += locationTitles[i] + "\n";
						}

						if (listedDefectLocations == "") {
							listedDefectLocations +=  user.translate('defects.extendedfilter.withoutlocations');
						}

						alertPopup(listedDefectLocations, user.translate('defects.extendedfilter.locations'));

					}.bind(this)));
				}

				if (currentDefects.detailFilter.subcontractors != "all") {
					this.$detailsFilterHeader.append($("<button class='filterElement ui-btn'>" + user.translate('sheet.column.subcontractor') + "</button>").bind( "vclick", function() {
						var listedSubcontractors = "";

						for (var i = 0; i < currentDefects.detailFilter.subcontractors.length; i++) {
							var subcontractorId =currentDefects.detailFilter.subcontractors[i];
							for (var j = 0; j < subcontractors.models.length; j++) {
								if (subcontractors.models[j].get('id') == subcontractorId) {
									listedSubcontractors += subcontractors.models[j].get('name') + "\n";
								}
							}
						}

						if (listedSubcontractors == "") {
							listedSubcontractors += user.translate('defects.extendedfilter.withoutsubcontranctor');
						}


						alertPopup(listedSubcontractors,  user.translate('sheet.column.subcontractor'));

					}.bind(this)));
				}

				if (currentDefects.detailFilter.inspections != "all") {
					this.$detailsFilterHeader.append($("<button class='filterElement ui-btn'>" + user.translate('defect.inspections.title') + " </button>").bind( "vclick", function() {
						var listedInspections = "";

						for (var i = 0; i < currentDefects.detailFilter.inspections.length; i++) {
							var inspectionId = currentDefects.detailFilter.inspections[i];
							var inspection = _.find(inspections.models, function (t) {
								return t.id == inspectionId;
							});

							if (inspection) {
								listedInspections += inspection.get("date") + " " +  inspection.get("title") + "\n";
							}
						}

						if (listedInspections == "") {
							listedInspections += user.translate('defects.extendedfilter.withoutinspection');
						}


						alertPopup(listedInspections, user.translate('defect.inspections.title'));

					}.bind(this)));
				}

				if (currentDefects.detailFilter.combinedStatusFilter != "" || currentDefects.detailFilter.status != "all"
					|| currentDefects.detailFilter.subcontractorStatus != "all" || currentDefects.detailFilter.principalStatus != "all"){
					this.$detailsFilterHeader.append($('<button class="filterElement ui-btn ">Status</button>').bind( "vclick", function() {


						if (currentDefects.detailFilter.combinedStatusFilter != "") {
							var combinedStatus = "";
							combinedStatus +=  user.translate('defects.extendedfilter.extendedstatus') + "\n " + currentDefects.detailFilter.combinedStatusFilter;
							alertPopup(combinedStatus, "Status");
						} else {
							var listedStatus = "";
							if (currentDefects.detailFilter.status != "all" && currentDefects.detailFilter.status != "none") {
								if(_.contains(currentDefects.detailFilter.status, "irrelevant")) {
									listedStatus += user.translate('defect.status.irrelevant') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.status, "determined")) {
									listedStatus += user.translate('defect.status.determined') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.status, "fix")) {
									listedStatus += user.translate('defect.status.fix') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.status, "open")) {
									listedStatus += user.translate('defect.status.open') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.status, "discount")) {
									listedStatus += " " + user.translate('defect.status.discount') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.status, "fixed")) {
									listedStatus += user.translate('defect.status.fixed') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.status, "observed")) {
									listedStatus += user.translate('defect.status.observed') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.status, "rejected")) {
									listedStatus += user.translate('defect.status.rejected') + '\n';
								}

								listedStatus = "Status: \n" + listedStatus + "\n";
							}


							var subcontractorStatus = "";
							if (currentDefects.detailFilter.subcontractorStatus != "all" && currentDefects.detailFilter.subcontractorStatus != "none") {
								if(_.contains(currentDefects.detailFilter.subcontractorStatus, "pending")) {
									subcontractorStatus += user.translate('defect.subcontractorstatus.pending') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.subcontractorStatus, "fixed")) {
									subcontractorStatus += user.translate('defect.subcontractorstatus.fixed') + '\n';
								}

								subcontractorStatus = user.translate('defects.extendedfilter.subcontractorstatus') + "\n" + subcontractorStatus + "\n";
							}

							var principalStatus = "";
							if (currentDefects.detailFilter.principalStatus != "all" && currentDefects.detailFilter.principalStatus != "none") {
								if(_.contains(currentDefects.detailFilter.principalStatus, "pending")) {
									principalStatus += user.translate('defect.principalstatus.pending') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.principalStatus, "accepted")) {
									principalStatus += user.translate('defect.principalstatus.accepted') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.principalStatus, "fixed")) {
									principalStatus += user.translate('defect.principalstatus.fixed') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.principalStatus, "rejected")) {
									principalStatus += user.translate('defect.principalstatus.rejected') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.principalStatus, "discount")) {
									principalStatus += user.translate('defect.principalstatus.discount') + '\n';
								}
								if(_.contains(currentDefects.detailFilter.principalStatus, "pendingrejected")) {
									principalStatus += user.translate('defect.principalstatus.pendingrejected') + '\n';
								}

								principalStatus = user.translate('defects.extendedfilter.principalstatus') +  "\n" + principalStatus + "\n";
							}

							alertPopup(listedStatus + subcontractorStatus + principalStatus, "Status");
						}



					}.bind(this)));
				}


				if (currentDefects.detailFilter.requests != "all" && currentDefects.detailFilter.requests != "none") {
					this.$detailsFilterHeader.append($("<button class='filterElement ui-btn'>" + user.translate('filter.escalationLevels') + "</button>").bind( "vclick", function() {
						var listedRequests = "";
						for (var i = 0; i < currentDefects.detailFilter.requests.length; i++) {
							if(i + 1 == currentDefects.detailFilter.requests.length) {
								listedRequests += currentDefects.detailFilter.requests[i];
							} else {
								listedRequests += currentDefects.detailFilter.requests[i] + ", ";
							}
						}
						listedRequests = user.translate('filter.chosenEscalationLevels') + "\n" + listedRequests;
						alertPopup(listedRequests, user.translate('filter.escalationLevels'));
					}.bind(this)));
				}


				if (currentDefects.detailFilter.createdBy != "all" && currentDefects.detailFilter.createdBy != "none" ) {
					this.$detailsFilterHeader.append($("<button class='filterElement ui-btn'>" + user.translate('defects.extendedfilter.creator') + " </button>").bind( "vclick", function() {
						var listedCreators = "";
						for (var i = 0; i < currentDefects.detailFilter.createdBy.length; i++) {
							listedCreators += currentDefects.detailFilter.createdBy[i] + "\n";
						}
						alertPopup(listedCreators, user.translate('defects.extendedfilter.creator'));
					}.bind(this)));
				}


				if (currentDefects.detailFilter.description != "all" && currentDefects.detailFilter.description != "none") {
					if (currentDefects.detailFilter.description[0] == "empty") {
						this.$detailsFilterHeader.append($("<button class='filterElement notInteractable ui-btn'>" + user.translate('defects.extendedfilter.withdescription') + "</button>"));
					} else {
						this.$detailsFilterHeader.append($("<button class='filterElement notInteractable ui-btn '>" + user.translate('defects.extendedfilter.withoutdescription') + "</button>"));
					}
				}

			} else {

				this.$detailsFilterHeader.hide();
				this.filterMode = "filter";
				this.afterChangeFilterMode();
			}

		},


		refreshDisplayOptions: function () {
			this.$actionsMenu.find('.orderBy').val([ this.orderBy ]).checkboxradio('refresh');
			this.$actionsMenu.find('.groupBy').val([ this.groupBy ]).checkboxradio('refresh');
			this.$actionsMenu.find('.filterMode').val([ this.filterMode ]).checkboxradio('refresh');
		},

		filterKeyUp: function (e) {
			if (e.keyCode == 13) {
				this.filter(e);
			}
		},

		afterChangeFilterMode: function () {
			this.$defectsLocation.toggle((this.filterMode === 'filter' || this.filterMode === 'search') && ($(window).width() >= 850 || this.$defectsLocation.attr('shown') === 'true'));
			this.$filters.toggle(this.filterMode === 'filter');

			this.$locationFilter.resize();
			this.$qrcodetrigger.toggle(this.filterMode === 'filter' && !_.isEmpty(currentProject.get('unitTree')));

			this.$('.download-pdf').prop('disabled',
				(!!window.oxmobile && !(iosapp.appavailable && iosapp.functionavailable('downloadAndSharePdf')))
				|| !!iosapp.windowsappavailable
				|| !watchdog.isConnected()
				|| (this.filterMode !== 'filter' && this.filterMode !== 'detail')
			);
			this.$('.download-excel, .export').prop('disabled',
				!!window.oxmobile
				|| !!iosapp.windowsappavailable
				|| !watchdog.isConnected()
				|| (this.filterMode !== 'filter' && this.filterMode !== 'detail')
			);// || this.filterMode === 'detail');
		},

		searchLocationsFromInput: function () {
			if (this.$defectSearchFilter.val().trim() !== "") {
				var locationNotNormalized = this.$defectSearchFilter.val().split('userinputstring')[1];
				var noHTMLString = this.$defectSearchFilter.val().replace(new RegExp(/(<([^>]+)>)/ig), ""); //removes tags
				var normalizedLocation = '';
				var userInput = noHTMLString.split('userinputstring')[0];

				this.refreshDisplayOptions();

				if (noHTMLString.substring(0, 8) === 'location') {
					normalizedLocation = noHTMLString.split('location ')[1];
					this.$defectSearchFilter.val('');

					//TODO: this whole section needs urgent refactoring!
					var locationParts = normalizedLocation.replace(new RegExp(/\s>\s/g), ">").split('>');
					var locationId = idutil.locationArrayToId(locationParts);
					this.selectLocation(locationId);
				} else if(_.contains(noHTMLString, 'userinputstring')) {
					if (locationNotNormalized === user.translate('defects.location.all')){
						this.$defectSearchFilter.val(userInput);
						this.selectLocation('');
					} else {
						//TODO: this whole section needs urgent refactoring!
						var locationParts =  noHTMLString.split('userinputstring')[1].replace(new RegExp(/\s>\s/g), ">").split('>');
						normalizedLocation = idutil.locationArrayToId(locationParts);
						this.$defectSearchFilter.val(userInput + ".locSpecificTag.");
						if (normalizedLocation.length > 0) {
							this.selectLocation(normalizedLocation);
						} else {
							this.filter();
						}

						this.$defectSearchFilter.val(userInput);
					}
				} else {
					this.$defectSearchFilter.val(userInput);
					this.filter();
				}
			} else {
				this.filter();
			}
		},

		selectLocation: function (choice) {

			var choice = choice.split("-");
			var tree = currentProject.get('unitTree');
			var location = [];
			var choices;
			var part = [];

			for(var i = 0; i < choice.length; i++) {
				tree = (tree && tree.children) ? tree.children[choice[i]] : null;
				if(tree){
					location.push(tree.name);
				}
			}

			for (var i = 0; i <= location.length; i++) {
				part.push(i < location.length ? Defect.toId(location[i]) : '');
			}

			choices = part.join("-");
			choices = choices.substring(0, choices.length - 1);
			this.filter(null, choices);
		},

		editDefects: function(e, isActionMenuOpen) {
			e.preventDefault();
			e.stopPropagation();

			if (user.isRestrictedSubcontractor()) {
				window.registrationRequestPopup();
				return;
			}
			if (this.checkedIds.length == 0) {
				window.alertPopup(user.translate('defects.menu.pleaseselect'));
				return;
			}
			if (!watchdog.isConnected()) {
				window.alertPopup(user.translate('defects.menu.onlyonline'));
				return;
			}
			if (offlineQueue.get('pending')) {
				window.alertPopup(user.translate('defects.menu.emptyqueue'));
				return;
			}

			if (isActionMenuOpen) {
				this.$actionsMenu.popup({
					afterclose: _.bind(function () {
						$('#defects-actions-menu .edit-defects').addClass('stayFocused');
						this.showEditDefectsPopup(e);
					}, this)
				}).popup('close');
			} else {
				$('#defects-actions-menu .edit-defects').addClass('stayFocused');
				setTimeout(function() {this.showEditDefectsPopup(e)}.bind(this), 200);
			}

		},

		changeCommentForSave: function (comment) {
			comment = comment.replace(/\n/g, '¶');
			comment = comment.replace(/↩/g, '¶');
			return comment;
		},

		addNewSubcontractorComment: function (defect, commentText) {
			var dateTxt = moment(new Date()).format('YYYY.MM.DD - HH-mm-ss: ')
			if (defect) {
				var uval = ' (';
				if (user.get('name')) {
					uval += user.get('name');
				} else if (user.get('experienceId')) {
					uval += user.get('experienceId');
				}
				if (user.isSubcontractor()) {
					uval += ', AN';
				}
				uval += ')';
				var subcontractorCommentVal = commentText
				if (subcontractorCommentVal) {
					subcontractorCommentVal = this.changeCommentForSave(subcontractorCommentVal);
					var oldCommentString = defect.get('scmt') ? defect.get('scmt').split('\n') : '';
					var subcontractorComment = _.compact(_.flatten([oldCommentString, dateTxt + subcontractorCommentVal + uval])).join('\n');
					defect.set('scmt', subcontractorComment);
				}
			}
		},

		addNewPrincipalComment: function (defect, commentText) {
			var dateTxt = moment(new Date()).format('YYYY.MM.DD - HH-mm-ss: ')
			if (defect) {
				var uval = ' (';
				if (user.get('name')) {
					uval += user.get('name');
				} else if (user.get('experienceId')) {
					uval += user.get('experienceId');
				}
				if (user.isPrincipal()) {
					uval += ', AG';
				}
				uval += ')';
				var commentVal = commentText;
				if (commentVal && commentVal !== user.translate('defect.rejectionreason')) {
					commentVal = this.changeCommentForSave(commentVal);
					var oldCommentString = defect.get('cmt') ? defect.get('cmt').split('\n') : '';
					var comment = _.compact(_.flatten([oldCommentString, dateTxt + commentVal + uval])).join('\n');
					defect.set('cmt', comment);
				}

			}
		},
		addToCurrentNotice: function (defect, textToAdd) {
			var text = defect.get('h') || '';
			text === '' ? text = textToAdd : text += ' ' + textToAdd;
			defect.set('h', text);
		},

		addToCurrentDescription: function (defect, textToAdd) {
			var text = defect.get('description') || '';
			text === '' ? text = textToAdd : text += ' ' + textToAdd;
			defect.set('description', text);
		},
		addToCurrentInternalComment: function (defect, textToAdd) {
			var text = defect.get('ic') || '';
			text === '' ? text = textToAdd : text += ' ' + textToAdd;
			defect.set('ic', text);
		},

		modifyText: function (defect,textInput) {

			if (textInput && typeof textInput === 'string' && textInput.length !== 0) {
				var $textType = this.$textTypeChooser.find('select.texttype').val();
				switch ($textType) {
					case 'scmt':
						this.addNewSubcontractorComment(defect, textInput);
						break;
					case 'cmt':
						this.addNewPrincipalComment(defect, textInput);
						break;
					case 'h':
						this.addToCurrentNotice(defect, textInput);
						break;
					case 'description':
						this.addToCurrentDescription(defect, textInput);
						break;
					case 'ic':
						this.addToCurrentInternalComment(defect, textInput);
						break;
				}
			}

		},

		showEditDefectsPopup: function (e){
			if (this.checkedIds.length == 0) {
				window.alertPopup(user.translate('defects.menu.pleaseselect'));
				return;
			}

			if (currentDefects.filtered().length == 0) {
				window.alertPopup(user.translate('defects.menu.nodefects'));
				return;
			}
			if (!watchdog.isConnected()) {
				window.alertPopup(user.translate('defects.menu.onlyonline'));
				return;
			}
			if (offlineQueue.get('pending')) {
				window.alertPopup(user.translate('defects.menu.emptyqueue'));
				return;
			}

			this.$changeDataDialogForSelectedDefects.show().popup('open',  { transition: 'flip'});
			this.$changeDataDialogForSelectedDefects.find('.defectcount').text(user.translate('defect.defecttype.defectcount') + " " + this.checkedIds.length);
			this.$changeDataDialogForSelectedDefects.find('.defectcount').toggle(true);

			var $defectType = this.$defectTypeChooser.find('select.defecttype');
			var defectTypes = currentProject.get('types');
			_.forEach(defectTypes, function (type) {
				if ($defectType.find('option[value="' + type.defectTypeId + '"]').length === 0) {
					$defectType.append($('<option class="type-' + type.defectTypeId + '" value="' + type.defectTypeId + '">').text(type.content + ' (' + user.translate(type.label) + ')'))

				}
			}.bind(this));
			$defectType.val("");
			$defectType.selectmenu('refresh');
			this.$defectTypeChooser.toggle(!user.isSubcontractor());

			this.$selectedDefectsEscalationLevelChooser.toggle(!user.isSubcontractor() && !user.isPrincipal());

			this.$('.selectedDefectsChangeDataDialog .defects-setsubcontractor').toggle(!user.isSubcontractor());

			var $deadline = this.$deadlineChooserSelectedDefects.find('input[type=date]');
			$deadline.val(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14).toISOString().substring(0, 10));
			this.$deadlineChooserSelectedDefects.find('.deadlinecg [type=radio]').prop('checked', false).filter('[value=""]').prop('checked', true).end().checkboxradio('refresh');
			this.$deadlineChooserSelectedDefects.toggle(!user.isSubcontractor() && (!currentDefects.elementFilter.external || !user.isPrincipal()));

			this.$changeDataDialogForSelectedDefects.find('.defects-setstatus   [type=radio]').prop('checked', false).checkboxradio('refresh');
			this.$changeDataDialogForSelectedDefects.find('.defects-setstatus').toggle(!user.isPrincipal() && !user.isSubcontractor());

			this.$changeDataDialogForSelectedDefects.find('.defects-setanstatus [type=radio]').prop('checked', false).filter('[value=""]').prop('checked', true).end().checkboxradio('refresh');
			this.$changeDataDialogForSelectedDefects.find('.defects-setagstatus [type=radio]').prop('checked', false).filter('[value=""]').prop('checked', true).end().checkboxradio('refresh');

			this.$pickDeadlineForSelectedDefects = this.$('#deadline-for-selected-defects-picker').parents().eq(0);
			this.$pickDeadlineForSelectedDefects.css('display', 'none');

			this.$changeDataDialogForSelectedDefects.find('.defects-setexternal').toggle(!user.isPrincipal() && !user.isSubcontractor());
			this.$changeDataDialogForSelectedDefects.find('.defects-setagstatus').toggle(user.isPrincipal());
			this.$changeDataDialogForSelectedDefects.find('.defects-setanstatus').toggle(!user.isPrincipal());

			this.setSubcontractorForSelectedDefects.val("");
			this.$progressSliderForSelectedDefects.toggle(false);

			this.cancelMultipleUpdate = false;
			var finishedProcessingDefects = 1;
			this.updateSliderSelectedDefectsProgress(0,this.checkedIds.length);

			var currentEscalationLevel = this.updateDefectsEscalationLevels();

			var $buttonMakeChanges = this.$changeDataDialogForSelectedDefects.find('.ok');
			var $buttonCancelChanges = this.$changeDataDialogForSelectedDefects.find('.cancel');
			var defectsStatusChangeFailed = [];
			var defectsSubcontractorChangeFailed = [];
			var defectsEscalationChangeFailed = [];
			var defectsEscalationChanged = [];
			var defectsEscalationChangeNotNeeded = [];
			var defectsTerminationNotAllowedByGroup = [];
			var defectsTerminationNotActivated = [];

			$buttonCancelChanges.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.defectIDCustomList = []
				this.cancelMultipleUpdate = true;
				this.$changeDataDialogForSelectedDefects.popup('close');
				$buttonCancelChanges.off('vclick');
				$buttonMakeChanges.off('vclick');
				$('#defects-actions-menu .edit-defects').removeClass('stayFocused');
			}, this));

			var defectsChanged = [];
			var defectsNotChanged = [];
			var defectsReadonly = [];


			$buttonMakeChanges.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				if (!watchdog.isConnected()) {
					this.resetCustomIdFilter();
					this.$changeDataDialogForSelectedDefects.popup('close');
					this.cancelMultipleUpdate = true;
					window.alertPopup(user.translate('defects.menu.onlyonline'));
					$('#defects-actions-menu .edit-defects').removeClass('stayFocused');
					return;
				}
				$buttonMakeChanges.off('vclick');
				if ($(e.currentTarget).hasClass('ok')) {
					var copyOfcheckedIds = this.checkedIds;
					var newType = $defectType.val();
					var status 					= this.$changeDataDialogForSelectedDefects.find('.defects-setstatus [type=radio]:checked').val();
					var subcontractorStatus 	= this.$changeDataDialogForSelectedDefects.find('.defects-setanstatus [type=radio]:checked').val();
					var principalStatus 		= this.$changeDataDialogForSelectedDefects.find('.defects-setagstatus [type=radio]:checked').val();
					var pf 						= this.$changeDataDialogForSelectedDefects.find('.defects-setagstatus [type=radio]:checked').attr('data-pf');
					var deadlineValue 			= this.$deadlineChooserSelectedDefects.find('[type=radio]:checked').val();
					var subcontractorSelected 	= this.setSubcontractorForSelectedDefects.val();
					var isSubcontractorWrong 	= false;
					var external 				= this.$changeDataDialogForSelectedDefects.find('.defects-setexternal [type=radio]:checked').val();
					var textInput = this.$textTypeChooser.find('input.textinput').val();
					var automaticSubChange 		= (this.$automaticAnSelection.length > 0 && this.$automaticAnSelection[0].dataset.cacheval === 'false');
					var escalationLevel 		= this.$selectedDefectsEscalationLevelChooser.find('input').filter(function () {return $(this).prop('checked') === true}).val();
					var keepSelectedDefects		= this.$('.keepSelectedDefectscg').find('input').prop('checked');

					if (!(newType || status || subcontractorStatus || principalStatus || deadlineValue || subcontractorSelected || external || textInput || automaticSubChange || (escalationLevel && currentEscalationLevel !== parseInt(escalationLevel) && escalationLevel !== 'nochange'))) {
						alertPopup(user.translate('defects.nochangehint'));
						return;
					}

					this.$changeDataDialogForSelectedDefects.find('.defectcount').toggle(false);
					this.$('.updateProgress').toggle(true);
					this.resetCustomIdFilter();

					var userGroupId = currentProject.get('groupId');
					var userGroup = (currentProject.get('groups') || {})[userGroupId];

					var queue = $.Deferred().resolve();
					_.each(copyOfcheckedIds, function (id) {
						var defect = currentDefects.get(id);

						var defectGroupId = defect.get('group');
						var defectGroup = (currentProject.get('groups') || {})[defectGroupId];

						if (!!(
							(userGroup && !user.isSubcontractor() && userGroup.groupEditGroupDefectsOnly && userGroupId !== defectGroupId) ||   //current user group allows only to edit group defects
							(defectGroup && !user.isSubcontractor() && defectGroup.groupDefectsEditOnlyByGroup && userGroupId !== defectGroupId)      //defect group allows only defect to be edited by group members
						)) {
							//this defect is read-only
							defectsReadonly.push(defect);
							return;
						}
						queue = queue.then(_.bind(function () {

							if (!this.cancelMultipleUpdate && defect) {
								var requestUuid =  Defect.generateUUID();
								if(defect.get('requests') !== 4) {



									if (newType &&	defect.get('type') !== newType) {
										defect.set('type', $defectType.val());
									}

									if (deadlineValue === "fixed") {
										defect.set('deadline', $deadline.val());
									}

									if (status) {
										if (defect.get('status') === status) {
											defect.set('statusUnchanged', true);
										}

										if (status === "discount" && (defect.get('type') == "safety" || defect.get('type') == "fireprotection")) {
											defect.set('statusUnchanged', true);
											defectsStatusChangeFailed.push(defect.get('id'));
											this.defectIDCustomList.push(defect.get('id'));
										} else {
											defect.set('status', status);
										}
									}
									if (external !== null && external !== "" && !defect.get('external')) {
										defect.set('external', true);
									}
									//Add to current inspection if linktoCurrentInspection set
									if(currentInspection != null && currentInspection.get("linkToCurrentInspection")){
										var inspections = defect.get('inspections').slice();
										inspections.push(currentInspection.id);
										defect.set('inspections', _.uniq(inspections));
									}

									this.modifyText(defect,textInput);

									var subcontractor = defect.get('subcontractor');
									var crew = defect.get('crew');
									var crewSubs = this.getAllSubcontractorsOfCrew(crew);
									defectsNotChanged.push(defect);
									if(currentProject.get('chooseSubAutomatically') && !subcontractor && crew && crewSubs && crewSubs.length === 1) {
										defect.set('subcontractor', crewSubs[0].id);
										defectsChanged.push(defect);
										defectsNotChanged.splice(defectsNotChanged.indexOf(defect), 1);
									} else if (subcontractorSelected && !defect.isNew() && !!subcontractor && subcontractors.validRegexp.test(subcontractor) && defect.get('requests') > 0) {
										defectsSubcontractorChangeFailed.push(defect.get('id'));
										this.defectIDCustomList.push(defect.get('id'));

									} else if (subcontractorSelected) {
										if (user.isPrincipal() && subcontractorSelected === user.translate('defect.primecontractor')) {
											if (currentProject.get('showSubcontractorsToPrincipal')) {
												defect.set('subcontractor', null);
											}
											defect.set('crew', subcontractorSelected);
										} else {
											if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
												if (currentProject.get('crews').indexOf(subcontractorSelected) === -1) {
													isSubcontractorWrong = true;

												} else {
													defect.set('crew', subcontractorSelected);
												}

											} else {
												var subcontractorId = this.subcontractorId(subcontractorSelected);
												if (subcontractorId == null) {
													isSubcontractorWrong = true;

												} else {
													defect.set('subcontractor', subcontractorId);
													defect.set('crew', subcontractors.get(subcontractorId).get('crew'));
												}
											}
										}
									}

									if (subcontractorStatus) {
										if (defect.get('subcontractorStatus') === subcontractorStatus) {
											defect.set('anStatusUnchanged', true);
										}
										defect.set('subcontractorStatus', subcontractorStatus);
									}

									if (principalStatus) {
										if (defect.get('principalStatus') === principalStatus) {
											defect.set('agStatusUnchanged', true);
										}
										defect.set('principalStatus', principalStatus);
										defect.set('pf', pf);
									}

									if (!user.isPrincipal() && !user.isSubcontractor() && escalationLevel && escalationLevel !== 'nochange') {
										var wasNext = false;
										var userGroupId = currentProject.get('groupId');
										var userGroup = (currentProject.get('groups') || {})[userGroupId];
										var noTerminate = userGroup && userGroup.groupDefectsNoTerminate;
										if (!noTerminate) noTerminate = false;
										var typeConfig = new Map(_.pairs(currentProject.get('typeConfiguration'))).get(defect.get('type'));
										var currentLevel = defect.get('requests');
										if (escalationLevel === 'next') {
											wasNext = true;
											escalationLevel = (currentLevel === 4 ? 4 : currentLevel + 1);
											escalationLevel = this.checkValidityOfEscalationLevel(escalationLevel, typeConfig);
										}
										this.defectIDCustomList.push(defect.get('id'));
										if (currentLevel === 4 && escalationLevel !== '4') {
											defectsEscalationChangeFailed.push(defect.get('id'));
										} else if (currentLevel !== parseInt(escalationLevel)) {
											defectsEscalationChanged.push(defect.get('id'));
											if (escalationLevel === '4' && noTerminate) {
												defectsTerminationNotAllowedByGroup.push(defect.get('id'));
											} else if (escalationLevel === '4' && !typeConfig.terminationLevel) {
												defectsTerminationNotActivated.push(defect.get('id'));
											} else {
												defect.set('requests', escalationLevel);
												if (escalationLevel === '4') defect.set('terminated', true);
											}
										} else {
											defectsEscalationChangeNotNeeded.push(defect.get('id'));
										}
										if(wasNext) {
											escalationLevel = 'next';
										}
									}
									defect.unset('attachments');

								} else {
									defectsEscalationChangeFailed.push(defect.get('id'));
									this.defectIDCustomList.push(defect.get('id'));
								}

								var result = $.Deferred();
								var options = {
									oaRequestId: requestUuid
								};
								var tmp = defect.save(null, options);

								Backbone.Events.once('multipleDefectSynced', _.bind(function () {
									finishedProcessingDefects += 1;
									this.updateSliderSelectedDefectsProgress(finishedProcessingDefects, copyOfcheckedIds.length);
									result.resolve();
								}, this));

								window.debugdeferred = tmp;

								if(finishedProcessingDefects === copyOfcheckedIds.length) {
									this.$changeDataDialogForSelectedDefects.popup('close');
									this.cancelMultipleUpdate = true;

									if (isSubcontractorWrong) {
										alertPopup(user.translate('defect.wrongsubcontractor'), "Achtung");
									}

									if (this.defectIDCustomList.length > 0) {
										var filterFunc = this.filter.bind(this);
										var resetCustomDefectIDFilterFunc = this.resetCustomIdFilter.bind(this);
										var message = "";
										if(defectsStatusChangeFailed.length > 0) {
											message += user.translate('defects.status.change.failed') + defectsStatusChangeFailed.length + "\n\n\n";
										}

										if (defectsSubcontractorChangeFailed.length > 0) {
											message += user.translate('defects.changehint') + defectsSubcontractorChangeFailed.length + "\n\n" + user.translate('defects.changehint1');
										}

										if (defectsEscalationChangeFailed.length > 0) {
											message += user.translate('defects.escalationlevel.cannotchangeterminated') + '\n\n' + user.translate('defects.escalationlevel.cannotchangeterminated1') + ': ' + defectsEscalationChangeFailed.length;
										}

										// if (defectsEscalationChangeNotNeeded.length > 0) {
										// 	message += user.translate('defects.escalationlevel.notneeded') + ' ' + defectsEscalationChangeNotNeeded.length;
										// }

										if (defectsEscalationChanged.length > 0) {
											message += user.translate('defects.escalationlevel.changed') + '\n\n' + _.map(defectsEscalationChanged, function (d) {
												return 'N' + d.toString();
											}).join(', ') + '\n';
										}

										if (defectsTerminationNotAllowedByGroup.length > 0) {
											message += user.translate('defects.escalationlevel.terminationnotallowed') + '\n\n' + user.translate('defects.escalationlevel.terminationnotallowed1') + ': ' + defectsTerminationNotAllowedByGroup.length;
										}

										if (defectsTerminationNotActivated.length > 0) {
											message += user.translate('defects.escalationlevel.terminationnotactivated') + '\n\n' + user.translate('defects.escalationlevel.terminationnotactivated1') + ': ' + defectsTerminationNotActivated.length;
										}
										if(message === ''){
											message = user.translate('defects.message.default');
										}
										alertPopupSubcontractorChangeRejected(message, "Achtung", this.filterMode , filterFunc, resetCustomDefectIDFilterFunc);
									}
								}
								$('#defects-actions-menu .edit-defects').removeClass('stayFocused');
								if(keepSelectedDefects) {
									this.refreshSelections("keepSelectedAfterEdit");
								} else {
									this.refreshSelections("deselect");
								}
								return result;
							}
						}, this));
					}, this);
					queue = queue.then(function () {
						this.$textTypeChooser.find('input.textinput').val('');

						var text = '';
						if(this.$automaticAnSelection.length > 0 && this.$automaticAnSelection[0].dataset.cacheval === 'false') {
							text = user.translate('defects.automaticChange.notice') + ': ' + defectsChanged.length + '\n'
								+ user.translate('defects.noAutomaticChange.notice') + ': ' + defectsNotChanged.length + '\n';
							if(defectsNotChanged.length > 0) {
								text += '\n' + user.translate('defects.noAutomaticChange.notice.details') + ':\n\n';
								defectsNotChanged.forEach(function (d) {
									text += 'N' + d.id + '\n';
								});
							}
						}
						if (defectsReadonly.length > 0) {
							text += user.translate('defects.readonly.notice') + ':\n\n';
							defectsReadonly.forEach(function (d) {
								text += 'N' + d.id + '\n';
							});
						}
						if (text) {
							window.alertPopup(text, 'Ergebnis');
						}
					}.bind(this));
				}
			}, this));
		},

		checkValidityOfEscalationLevel: function (level, typeConfig) {
			switch (level) {
				case 4:
					if(!typeConfig.terminationLevel) level = 3;
					break;
				case 3:
					if(!typeConfig.thirdEscalationLevel && !typeConfig.terminationLevel) {
						level = 2;
					} else if (!typeConfig.thirdEscalationLevel && typeConfig.terminationLevel) {
						level = 4;
					}
					break;
				case 2:
					if(!typeConfig.secondEscalationLevel && !typeConfig.terminationLevel) {
						level = 1;
					} else if (!typeConfig.secondEscalationLevel && typeConfig.terminationLevel) {
						level = 4;
					}
					break;
			}
			return level;
		},

		updateDefectsEscalationLevels: function () {
			var types = currentProject.get('types');
			var selectedDefects = _.filter(currentDefects.filtered(), function (d) {
				return _.contains(this.checkedIds, d.id);
			}.bind(this))
			var dtypes = [];
			var levels = [];
			_.each(selectedDefects, function (d) {
				if(!_.contains(dtypes, d.get('type'))) dtypes.push(d.get('type'));
				if(!_.contains(levels, d.get('requests'))) levels.push(d.get('requests'));
			})
			this.$selectedDefectsEscalationLevelChooser.find('span').toggle(false);
			this.$selectedDefectsEscalationLevelChooser.find('div.ui-radio').toggle(true);
			var typeConfigs = [];
			_.each(currentProject.get('typeConfiguration'), function (t, key) {
				_.each(dtypes, function (dt) {
					if(dt === key) typeConfigs.push(t);
				})
			})
			var e1 = false, e2 = false, e3 = false, e4 = false;
			_.each(typeConfigs, function (tc) {
				if(tc.firstEscalationLevel) e1 = true;
				if(tc.secondEscalationLevel) e2 = true;
				if(tc.thirdEscalationLevel) e3 = true;
				if(tc.terminationLevel) e4 = true;
			})
			var userGroupId = currentProject.get('groupId');
			var userGroup = (currentProject.get('groups') || {})[userGroupId];
			var noTerminate = userGroup && userGroup.groupDefectsNoTerminate;
			if(!noTerminate) noTerminate = false;
			this.$escalationLevelChooserRadio1.closest('div').toggle(e1)
			this.$escalationLevelChooserRadio2.closest('div').toggle(e2)
			this.$escalationLevelChooserRadio3.closest('div').toggle(e3)
			this.$escalationLevelChooserRadio4.closest('div').toggle(e4).toggleClass('ui-disabled', noTerminate).find('label').toggleClass('ui-disabled', noTerminate);
			this.$selectedDefectsEscalationLevelChooser.find('label').removeClass('ui-btn-active');
			if(levels.length === 1 && levels[0] !== 4) {
				this.$selectedDefectsEscalationLevelChooser.find('input[value=' + levels[0] + ']').prop('checked', true)
					.closest('div').find('label').addClass('ui-btn-active');
			} else {
				this.$escalationLevelChooserRadioNoChange.prop('checked', true).closest('div').find('label').addClass('ui-btn-active');
			}
			return levels;
		},

		getAllSubcontractorsOfCrew: function (crew) {
			var crewSubs = [];
			subcontractors.forEach(function (s) {
				if(s.get('crew') && s.get('crew') !== '' && crew === s.get('crew')) crewSubs.push(s);
			})
			return crewSubs;
		},

		filter: function (e, choice, favourite) {
			var $target = e && $(e.target);
			var defectCustomIDListChanged;
			if (window.localStorage && window.localStorage.hasOwnProperty("defects_filter")){
				var defectCustomIDOld = JSON.parse(window.localStorage.getItem("defects_filter")).customDefects;
				if (defectCustomIDOld) {
					defectCustomIDListChanged = this.defectIDCustomList.length !== defectCustomIDOld.length;
				} else {
					defectCustomIDListChanged = true;
				}
			} else {
				defectCustomIDListChanged = true;
			}

			var shouldRefreshFilter = (!!$target || defectCustomIDListChanged || !!choice || !!favourite || window.localStorage.hasOwnProperty("fromSidePanel") || !!this.$defectSearchFilter.val());

			var inspectionVal = this.$inspectionFilter.val();
			if (!!$target || window.localStorage.hasOwnProperty("fromSidePanel")) {
				this.resetCustomIdFilter();
				this.toResetCustomerIdFilter = false; //reset the resetter :D
			}
			if (inspectionVal === user.translate('defects.filter.anyinspection')) {
				this.$inspectionFilter.val('');
			}

			var subcontractorVal = this.$subcontractorFilter.val();
			if (subcontractorVal === user.translate('defects.filter.anysubcontractor') || subcontractorVal === user.translate('defects.filter.anycrew')) {
				this.$subcontractorFilter.val('');
			}

			var escalationLevels = [];
			if(currentDefects.elementFilter.escalationLevels && _.contains(currentDefects.elementFilter.escalationLevels, 'readyfortermination')) escalationLevels.push('readyfortermination');
			_.each(this.multiselect.selectedItems, function (i) {
				if(Array.isArray(i)) {
					_.each(this.escalationFilters, function (f) {
						if(i[0] === f[0]) escalationLevels.push(i[0].toLowerCase());
					})
					_.each(this.terminationFilter, function (f) {
						if(i[0] === f[0]) escalationLevels.push('X');
					})
				} else {
					if(this.escalationFilters.indexOf(i) > -1) escalationLevels.push(i.toLowerCase());
					if(i === 'X') escalationLevels.push('X');
				}

			}.bind(this));

			var deadlineFilters = [];
			_.each(this.multiselect.selectedItems, function (i) {
				if(Array.isArray(i)) {
					_.each(this.deadlineFilters, function (f) {
						if(i[0] === f[0]) deadlineFilters.push(i[0].toLowerCase());
					})
				} else {
					if(this.deadlineFilters.indexOf(i) > -1) deadlineFilters.push(i.toLowerCase());
				}
			}.bind(this));

			var externalFilter = this.$defectExternalFilterCheckbox.prop('multichecked');

			var from = (this.from && this.from !== '' ? new Date(this.from) : null);
			var to = (this.to && this.to !== '' ? new Date(this.to) : null);
			var creationDateFilter = this.correctDates({from: from, to: to});



			//prepare new filter
			var newFilter = _.extend(currentDefects.freshFilter(), {
				search:         this.$defectSearchFilter.val() || null,
				unit:           _.isString(choice) ? choice : (($target && $target.parents('#locationFilter').length > 0) ? $target.val() : currentDefects.elementFilter.unit),
				subcontractor:  this.subcontractorId(subcontractorVal),
				inspection:     this.inspectionId(inspectionVal),
				status:         this.$statusFilter.val() || null,
				type:           this.$defectTypeFilter.val() || null,
				favourite:		$('.filter-only-favourite-no').css('display') === 'none',
				customDefects: 	this.defectIDCustomList,
				escalationLevels: escalationLevels,
				deadlineFilters: deadlineFilters,
				externalFilter: externalFilter,
				creationDateFilter: creationDateFilter,
				requestDefects: currentDefects.elementFilter.requestDefects
			});

			localStorage.setItem("defects_filter", JSON.stringify(newFilter));
			//on refresh set the filter values
			if(shouldRefreshFilter){
				this.refreshInspectionFilterList(newFilter.inspection);
				this.refreshSubcontractorFilterList(newFilter.subcontractor);
				this.refreshStatusFilterList(newFilter.status);
				this.refreshTypeFilterList(newFilter.type)
				this.refreshFavouriteFilter(newFilter.favourite, true);
			}

			this.$customDefectsNotifier.toggle(this.defectIDCustomList && this.defectIDCustomList.length > 0);
			//Say that next time the customerIdFilter should be reset. It is not reset now for the
			// case that the user goes into the defect and then returns.
			if(this.defectIDCustomList && this.defectIDCustomList.length > 0){
				this.toResetCustomerIdFilter = true;
			}

			if (newFilter.unit && newFilter.search && !_.contains(newFilter.search, ".locSpecificTag.")) {
				newFilter.search = newFilter.search + ".locSpecificTag.";
			}

			//special status queries
			this.setCombinedStatus(newFilter);
			// unit search regex speedup
			if (newFilter.unit !== null && newFilter.unit.indexOf('*') !== -1 && user.get('settings').altLocationFilter) {
				var p = newFilter.unit.split('-');
				while (p.length > 1 && p[p.length - 1] == '*' && p[p.length - 2] == '*') { //remove trailing asterisks
					p.pop();
				}
				newFilter.regexpUnit = new RegExp('^' + p.join('-').replace(/\*/g, '[^\\-]*'));
			}

			if (!_.isEqual(newFilter, currentDefects.elementFilter)) {
				_.extend(currentDefects.elementFilter, newFilter);
				this.refreshList({ newListContext: true });
			}
			this.refreshLocation();
		},


		subcontractorId: function (val) {
			if (!val || val === user.translate('defects.filter.anysubcontractor') || val === user.translate('defects.filter.anycrew')) {
				return null;
			}
			if (val === user.translate('defects.filter.emptysubcontractor')) {
				return 'null';
			}
			var subcontractor = subcontractors.find(function (model) { return model.getLabel() === val; });
			return subcontractor ? subcontractor.id : val;
		},

		inspectionId: function (val) {
			if (!val || val === user.translate('defects.filter.anyinspection')) {
				return null;
			}
			if (val === user.translate('defects.filter.emptyinspection')) {
				return 'null';
			}
			var index = _.indexOf(inspections.labelIndex, val);
			return index !== -1 ? inspections.at(index).id : null;
		},

		setCombinedStatus: function (filter) {
			filter.extraStatus = null;
			if (filter.status && filter.status.indexOf('agcost') === 0) {
				filter.cost = filter.status.substr(2);
				filter.status = 'pending';
			} else if (filter.status && filter.status.indexOf('cost') === 0) {
				filter.cost = filter.status;
				filter.status = 'pending';
			}  else if (filter.status === 'pending-subcontractor-fixed') {
				filter.status = 'pending';
				filter.subcontractorStatus = 'fixed';
				filter.extraStatusAN = 'S';
			} else if (filter.status === 'pending-subcontractor-pending') {
				filter.status = 'pending';
				// filter.subcontractorStatus = 'fixed';
				filter.extraStatusAN = 'G or empty';
			} else if (filter.status === 'all-principal-rejected') {
				filter.status = null;
				filter.principalStatus = 'rejected';
			} else if (filter.status === 'all-principal-discount') {
				filter.status = null;
				filter.principalStatus = 'discount';
			} else if (filter.status === 'all-principal-accepted') {
				filter.status = null;
				filter.principalStatus = 'accepted';
			} else if (filter.status === 'all-principal-pendingrejected') {
				filter.status = null;
				filter.principalStatus = 'pendingrejected';
			} else if (filter.status === 'done-principal-pending') {
				filter.status = 'done';
				filter.principalStatus = 'pending';
			} else if (filter.status === 'all-principal-pending') {
				filter.status = null;
				filter.principalStatus = 'pending';
			} else if (filter.status === 'extrastatusp') {
				filter.status = 'fixed';
				filter.principalStatus = 'rejected';
				filter.extraStatus = 'P';
			} else if (filter.status === 'pending-principal-accepted') {
				filter.status = 'open';
				filter.principalStatus = 'accepted';
			} else if (filter.status === 'extrastatusg') {
				filter.status = 'fixed';
				filter.principalStatus = 'rejected';
				filter.extraStatus = 'G';
			} else if (filter.status === 'extrastatusas') {
				filter.status = 'fix';
				filter.subcontractorStatus = 'fixed';
				filter.extraStatusAN = 'S';
			} else if (filter.status === 'extrastatusag') {
				filter.status = 'fix';
				filter.subcontractorStatus = 'fixed';
				filter.extraStatusAN = 'G';
			} else if (filter.status === 'request1-overdue') {
				filter.status = 'pending';
				filter.requests =  1;
				filter.overdue = true;
			} else if (filter.status === 'request2-overdue') {
				filter.status = 'pending';
				filter.requests =  2;
				filter.overdue = true;
			} else if (filter.status === 'agoverdue') {
				filter.status = 'pending';
				filter.agoverdue = true;
			} else if (filter.status === 'subcontractor-fixed-commented') {
				filter.status = null;
				filter.subcontractorStatus = 'fixed';
				filter.hasNewSCMT = true;

			} else if (filter.status === 'subcontractor-pending-commented') {
				filter.status = null;
				filter.subcontractorStatus = 'pending';
				filter.hasNewSCMT = true;

			} else if (filter.status === 'subcontractor-has-new-comments') {
				filter.status = null;
				filter.hasNewSCMT = true;
			} else if (filter.status === 'subcontractor-pending-all-changes') {
				filter.status = null;
				filter.subcontractorStatus = 'pending';
				filter.allSubChanges = true;
			} else if (filter.status === 'subcontractor-fixed-all-changes') {
				filter.status = null;
				filter.subcontractorStatus = 'fixed';
				filter.allSubChanges = true;
			} else if (filter.status === 'subcontractor-all-changes') {
				filter.status = null;
				filter.allSubChanges = true;
			} else if (filter.status === 'principal-all-changes') {
				filter.status = null;
				filter.allPrincipalChanges = true;
			}
		},

		getCombinedStatus: function (filter) {
			if (filter.extraStatus) {
				if (filter.extraStatus === 'P') {
					return 'extrastatusp';
				}
				if (filter.extraStatus === 'G') {
					return 'extrastatusg';
				}
			}
			if (filter.extraStatusAN) {
				if (filter.extraStatusAN === 'S') {
					return 'extrastatusas';
				}
				if (filter.extraStatusAN === 'G') {
					return 'extrastatusag';
				}
			}
			if (filter.cost) {
				return filter.cost;
			}
			if (filter.costAG) {
				return 'ag' + filter.costAG;
			}
			if (filter.status === 'pending' && filter.subcontractorStatus === 'fixed') {
				return 'pending-subcontractor-fixed';
			}
			if (filter.status === 'pending' && filter.subcontractorStatus === 'pending') {
				return 'pending-subcontractor-pending';
			}
			if(filter.status === 'pending' && filter.requests === 1 && filter.overdue === true){
				return 'request1-overdue';
			}
			if(filter.status === 'pending' && filter.requests === 2 && filter.overdue === true){
				return 'request2-overdue';
			}
			if(filter.status === 'pending' && filter.agoverdue === true){
				return 'agoverdue';
			}
			if(filter.hasNewSCMT == true && filter.subcontractorStatus === 'fixed') {
				return 'subcontractor-fixed-commented';
			}
			if(filter.hasNewSCMT === true && filter.subcontractorStatus === 'pending') {
				return 'subcontractor-pending-commented';
			}
			if(filter.hasNewSCMT === true) {
				return 'subcontractor-has-new-comments';
			}
			if(filter.allSubChanges === true && filter.subcontractorStatus === 'fixed') {
				return 'subcontractor-fixed-all-changes';
			}
			if(filter.allSubChanges === true && filter.subcontractorStatus === 'pending') {
				return 'subcontractor-pending-all-changes';
			}
			if(filter.allSubChanges === true) {
				return 'subcontractor-all-changes';
			}
			if(filter.allPrincipalChanges === true) {
				return 'principal-all-changes';
			}
			if (filter.principalStatus === 'pendingrejected') {
				return 'all-principal-pendingrejected';
			}
			if (filter.principalStatus === 'rejected') {
				return 'all-principal-rejected';
			}
			if (filter.principalStatus === 'discount') {
				return 'all-principal-discount';
			}
			if (filter.principalStatus === 'accepted') {
				return 'all-principal-accepted';
			}
			if (filter.status === 'done' && filter.principalStatus === 'pending') {
				return 'done-principal-pending';
			}
			if (filter.principalStatus === 'pending') {
				return 'all-principal-pending';
			}
			return filter.status;
		},

		refreshLocation: function () {
			var i;

			var $container = this.$locationFilter.controlgroup('container');
			if (currentProject.isNew()) {
				$container.empty();
			} else {
				var unit = currentProject.get('unitTree');
				var parts = (currentDefects.elementFilter.unit || '').split('-');

				var choices = [];

				if (user.get('settings').altLocationFilter) {
					var unitParts = currentProject.get('unitParts');

					var fullparts = [];
					for (i = 0; i < unitParts.length; i++) {
						fullparts.push(i < parts.length && parts[i] ? parts[i] : '*');
					}
					function partVal (index, part) {
						var val = fullparts.slice();
						if (arguments.length == 2) {
							val[index] = part;
						}
						return val.join('-');
					}
					for (i = 0; i < unitParts.length; i++) {
						var choice = $('<select>');
						$('<option>').text(currentProject.getStructureKey(i)).val(partVal(i, '*')).appendTo(choice);
						var possible = [], impossible = [];
						_.each(unitParts[i], function (unitPart) {
							var v = partVal(i, unitPart.part), avail = currentProject.unitAvail(v);
							if (avail) {
								possible.push({
									name: unitPart.name,
									val: v
								});
							} else {
								impossible.push(unitPart.name)
							}
						}, this);
						_.each(_.first(possible, 200), function (v) {
							$('<option>')
								.text(v.name)
								.val(v.val)
								.appendTo(choice);
						});
						if (possible.length > 200) {
							$('<option>').prop('disabled', true).text('\u2026 (zu viele Einträge)').appendTo(choice);
						}
						if (possible.length + impossible.length < 200) {
							if (possible.length > 0 && impossible.length > 0) {
								$('<option>').prop('disabled', true).text('\u2014').appendTo(choice);
							}
							_.each(impossible, function (v) {
								$('<option>')
									.prop('disabled', true)
									.text(v)
									.appendTo(choice);
							});
						}
						choice.val(partVal());
						choices.push(choice);
					}

				} else {
					for (i = 0; i <= parts.length && unit.children; i++) {
						var choice = $('<select>');
						$('<option>').text(currentProject.getStructureKey(i)).val(unit.path).appendTo(choice);
						if (i > 0) {
							$('<option>').text(user.translate('defects.withoutspecification')).val("" + unit.path + "-null").appendTo(choice);
						} else if (i === 0) {
							$('<option>').text(user.translate('defects.withoutspecification')).val("null").appendTo(choice);
						}

						_.each(_.compact(_.values(unit.children)), function (child) {
							if (child) {
								$('<option>')
									.text(child.name)
									.val(child.path)
									.appendTo(choice);
							}
						}, this);
						choices.push(choice);
						if (i < parts.length && parts[i]) {
							if (parts[i] == "null"){
								if (unit.path) {
									choice.val("" + unit.path + "-null");
								} else {
									choice.val("null");
								}
								break;
							};
							unit = unit.children[parts[i]];
							if (unit) {
								choice.val(unit.path);
							} else {
								break;
							}
						} else {
							break;
						}
					}
				}

				this.$locationFilter.find('select').remove();
				for (i = choices.length - 1; i >= 0; i--) {
					choices[i]
						.prependTo($container)
						.selectmenu();
				}
			}
			this.$locationFilter.controlgroup('refresh');

			this.resizeLocationInput();

			$('#locationFilter div.ui-controlgroup-controls').children().each(function () {

				if(_.contains($(this).find('span').text(), user.translate('defects.anylocation'))) {
					$(this).find('span').css({'color':'#aaa', 'opacity':'0.5'});
				}
			})
		},

		setDeadline: function () {
			if (currentDefects.filtered().length == 0) {
				window.alertPopup(user.translate('defects.menu.nodefects'));
				return;
			}
			else if (this.checkedIds.length == 0) {
				window.alertPopup(user.translate('defects.menu.pleaseselect'));
				return;
			}
			this.$deadlineDialog.show().popup('open');
			var $deadline = this.$deadlineDialog.find('input[type=date]');
			$deadline.val(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14).toISOString().substring(0, 10));

			var $buttons = this.$deadlineDialog.find('button');
			$buttons.one('vclick', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				if ($(e.currentTarget).hasClass('ok')) {
					var queue = $.Deferred().resolve();
					_.each(this.checkedIds, function (id) {
						var defect = currentDefects.get(id);
						if (defect) {
							defect.set('deadline', $deadline.val());
							queue = queue.then(function () {
								var result = $.Deferred();
								defect.save().always(function () {
									result.resolve();
								});
								return result;
							});
						}
					}, this);
				}
				$buttons.off('vclick');
				this.$deadlineDialog.popup('close');
			}, this));
		},

		updateSliderSelectedDefectsProgress: function( finished, checkedSize) {
			var value = checkedSize !== 0 ? (finished/checkedSize) *100 : 0;
			this.$('#slider').val(value);
			this.$('#slider').slider("refresh");
			this.$changeDataDialogForSelectedDefects.find('.defectcountRatio').text(finished + " / " + checkedSize);

		},

		linkDefects: function (e) {
			if(!e) {
				return;
			}
			e.preventDefault();
			e.stopPropagation();

			window.confirmPopup(user.translate('defects.linkdefects.confirm')).then(_.bind(function () {
				var createdAttachmentSources = this.checkedIds.map((id) => {
					return {
						id: Defect.generateUUID(),
						index: -1,
						type: this.linkDefectsParams.type,
						image_UUID: null,
						file: null,
						context: {},
						defect: id,
						location: this.linkDefectsParams.location,
						properties: {...this.linkDefectsParams.properties, defectUuid: Defect.generateUUID()}
					}
				});
				var customerId = this.linkDefectsParams.customerId;
				var projectId = this.linkDefectsParams.projectId;
				$.ajax({
					url:   `/onlineBauabnahme/api/${customerId}/${projectId}/defectAttachmentSources`,
					type: 'POST',
					data: JSON.stringify(createdAttachmentSources),
					dataType:    'json',
					contentType: 'application/json',
					processData: false,
				})
					.done(() => {
						var defects = currentDefects.filter(defect => this.checkedIds.includes(defect.get('projectId')));
						var data = defects.map(defect => {
							var attachmentSource = createdAttachmentSources.find(as => as.defect === defect.get('projectId'));
							var res = {
								customer: customerId,
								project: projectId,
								description: defect.get('description'),
								defectUuid: attachmentSource.properties.defectUuid,
								projectId: defect.get('projectId')
							}
							if(attachmentSource.type === 'CHECKLIST') {
								res.checklistId = attachmentSource.properties.checklistId;
								res.questionId = attachmentSource.properties.questionId;
							}
							return res;
						});

						var dataStr = JSON.stringify({data, command: 'linkDefects'});
						if(iosapp.appavailable) {
							iosapp.call(createdAttachmentSources[0].type === 'CHECKLIST' ? 'toQm' : 'toOt2', {data:dataStr});
						} else {
							localStorage.setItem('fromOa', dataStr);
						}
						attachmentSources.add(createdAttachmentSources);
					})
					.fail(e => {
						window.alertPopup(user.translate("defects.linkdefects.failed"));
					})
			}, this));
		},

		sendChosen: function (e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
				if (!watchdog.isConnected()) {
					window.alertPopup(user.translate('defects.menu.onlyonline'));
					return;
				}
				if (currentDefects.filtered().length == 0) {
					window.alertPopup(user.translate('defects.menu.nodefects'));
					return;
				}
				else if (this.checkedIds.length == 0) {
					window.alertPopup(user.translate('defects.menu.pleaseselect'));
					return;
				}

				this.router.requestBundlePage.setModel(this.checkedIds);
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/requestBundle');
			}
		},


		refreshList: function (options) {
			currentDefects.groupOrderDefectsBy(this.groupBy, this.orderBy);
			if (this.groupBy === "status") {
				this.groupKeys = currentDefects.groupOrder != null ? currentDefects.groupOrder : _.keys(currentDefects.defectsGrouped);
			} else {
				this.groupKeys = currentDefects.groupOrder != null ? currentDefects.groupOrder : _.sortBy(_.keys(currentDefects.defectsGrouped));
			}
			this.keyIndex = -1;
			this.itemIndex = 0;
			this.itemCount = 0;

			if (options && options.newListContext) {
				this.showFirstCount = 0;
				this.refreshSelections('deselect');
			}

			this.renderList();
		},

		renderList: function (options) {
			var currentInspectionType = _.filter(user.get('inspections'), function (inspection) {
				if(inspection.id === currentInspection.get('type')) {
					return true;
				} else {
					return false;
				}
			});
			this.isJourFixeInspection = !!(currentInspectionType && currentInspectionType[0] && currentInspectionType[0].fields && currentInspectionType[0].fields.topics && this.router.currentPage.id === 'inspection');

			options = options || {};
			var size = localStorage.getItem('listSize') || (options.dontEmpty ? this.pageSize : (this.prevPageSize ? this.prevPageSize : this.pageSize));
			localStorage.removeItem('listSize');
			if (!options.dontEmpty) {
				this.$list.empty();
			}

			var defectTypeName = "";
			//console.log(currentProject.get('types')[0]);
			if(currentInspection.id && currentInspection.getType()) {
				var defectType = _.find(currentProject.get('types'), function(el) {
					return el.defectTypeId === currentInspection.getType().defectType;
				}.bind(this))
				defectTypeName = defectType && defectType.importExportLabel || "";
			}
			defectTypeName = defectTypeName != "" ? defectTypeName : user.translate('defects.newdefect');
			$(".newDefectHeaderButton").text(defectTypeName).attr('title', defectTypeName);

			if (this.groupKeys.length == 0) {
				if (currentDefects.size() == 0) {
					$('<li class="none">').text(user.translate('defects.nodefects')).appendTo(this.$list);
				} else {
					$('<li class="none">').text((currentDefects.elementFilter && currentDefects.elementFilter.favourite) ? user.translate('defects.nofavouritedefectsmatching'): user.translate('defects.nodefectsmatching')).appendTo(this.$list);
				}

				this.$button = $('<div class="ui-btn-inline"><button style="max-width: 200px" title="' + defectTypeName + '" data-shortcut="newDefect" class="newDefect ui-btn ui-btn-inline ui-btn-icon-left ui-icon-plus ui-corner-all">' + defectTypeName + '</button></div>').appendTo(this.$list);
				this.$button.toggle(!user.isReadonly());
			} else {
				var goOn = false;
				for (var i = 0; i < size;) {
					var key = this.groupKeys[this.keyIndex];
					var defects = currentDefects.defectsGrouped[key];

					if (key == null || (!!defects && this.itemIndex >= defects.length) || goOn) {
						goOn = false;
						this.keyIndex++;
						this.itemIndex = 0;
						if (this.keyIndex >= this.groupKeys.length) {
							break;
						}
						key = this.groupKeys[this.keyIndex];
						defects = currentDefects.defectsGrouped[key];
						if (!defects) {
							continue;
						}
						this.renderDivider(this.groupKeys[this.keyIndex] + ' (' + defects.length + ')', key);
					} else {
						// var open = this.$('.defects-list [data-group="' + key + '"][data-role="list-divider"]')[0].dataset.open;
						var open = this.groupToggles ? (this.groupToggles.get(key) ? this.groupToggles.get(key) : 'true') : 'true';
						if (!defects || this.itemIndex >= defects.length) {
							continue;
						}

						if(open !== 'true') {
							goOn = true;
						} else {
							this.renderDefect(defects[this.itemIndex++], key, open);
							i++;
							this.itemCount++;
						}
					}
				}

				this.$list.enhanceWithin();

				if (this.hasMoreItemsInList()) {
					if (!options.noRecursion) {
						var recursionOptions = {
							dontEmpty:   true,
							noRefresh:   true,
							noRecursion: true
						};
						while(this.itemCount < this.showFirstCount && this.hasMoreItemsInList()) {
							this.renderList(recursionOptions);
						}
						if (this.hasMoreItemsInList()) {
							if (this.$moreRows) {
								this.$moreRows.detach();
								this.$list.append(this.$moreRows);
							} else {
								this.$moreRows = $('<li class="moreRows"><button>' + user.translate('defects.more') + '</button></li>').appendTo(this.$list).enhanceWithin();
							}
						}
					}
				} else if (!options.noRecursion && this.$moreRows) {
					this.$moreRows.detach();
					this.$button = $('<div class="ui-btn-inline"><button style="max-width: 200px" title="' + defectTypeName + '" data-shortcut="newDefect" class="newDefect ui-btn ui-btn-inline ui-btn-icon-left ui-icon-plus ui-corner-all">' + defectTypeName + '</button></div>').appendTo(this.$list);
					this.$button.toggle(!user.isReadonly());
					this.addAlternateDefectCreationButtons();
				} else {
					this.$button = $('<div class="ui-btn-inline"><button style="max-width: 200px" title="' + defectTypeName + '" data-shortcut="newDefect" class="newDefect ui-btn ui-btn-inline ui-btn-icon-left ui-icon-plus ui-corner-all">' + defectTypeName + '</button></div>').appendTo(this.$list);
					this.$button.toggle(!user.isReadonly());
					this.addAlternateDefectCreationButtons();
				}
				if (!options.noRecursion) {
					this.showFirstCount = this.itemCount;
				}
			}

			// var scroll = localStorage.getItem('scroll');
			// scroll && this.$list.scrollTop(scroll);

			//localStorage.removeItem('scroll');

			if (!options.noRefresh) {
				this.$list.listview('refresh');
				this.refreshSelections();
			}
			this.renderMetadata();
			shortcuts.getShortcutsHighlighter().highlightShortcuts();
			this.isJourFixeInspection = false;
		},

		addAlternateDefectCreationButtons: function () {
			if(currentInspection && currentInspection.get('id')) {
				var inspectionType = _.find(user.get('inspections'), function(e) {return e.id === currentInspection.get('type')});
				if(inspectionType && inspectionType.alternateDefectTypes && inspectionType.alternateDefectTypes.length > 0) {
					var alternateDefectTypes = [];
					_.each(inspectionType.alternateDefectTypes, function (type) {
						if(inspectionType.defectType !== type && alternateDefectTypes.indexOf(type) === -1) alternateDefectTypes.push(type);
					})
					_.each(alternateDefectTypes, function (type) {
						var defectType = _.find(currentProject.get('types'), function (pType) {return pType.defectTypeId === type})
						var defectTypeName = defectType ? defectType.importExportLabel : '';
						if(defectTypeName !== '') {
							var button = $('<div class="ui-btn-inline"><button style="max-width: 200px" title="' + defectTypeName + '" data-alternatetype="' + type + '" class="newDefect ui-btn ui-btn-inline ui-btn-icon-left ui-icon-plus ui-corner-all">' + defectTypeName + '</button></div>').appendTo(this.$list);
							button.toggle(!user.isReadonly());
						}
					}.bind(this))
				}
			}
		},

		renderMetadata: function() {
			var settings = user.get('settings');
			var defectMetadataHeader = this.$defectListHeader.find('.defect-metadata');
			defectMetadataHeader.empty();
			if (defectMetadataHeader.find('.adjustColumns').length === 0){
				defectMetadataHeader.append( "<div class='adjustColumns ui-btn-inline'></div>");
			}
			var columnsUsed = 0;
			if (settings.defectMetadata.defect_show_creator && defectMetadataHeader.find('.defect-creator-header').length=== 0){
				defectMetadataHeader.append( "<div class='defect-creator-header'><span>" +user.translate('defects.header.created.by') + "</span></div>");
				columnsUsed++;
			}
			if (settings.defectMetadata.defect_show_subcontractor && defectMetadataHeader.find('.defect-subcontractor-header').length === 0){
				defectMetadataHeader.append( "<div class='defect-subcontractor-header'><span>" + ((!user.isPrincipal() || currentProject.get('showSubcontractorsToPrincipal')) ? user.translate('defects.header.subcontractor') : user.translate('defects.header.crew')) + "</span></div>");
				columnsUsed++;
			}
			if (settings.defectMetadata.defect_show_creation_date && defectMetadataHeader.find('.defect-creation-date-header').length === 0){
				defectMetadataHeader.append( "<div class='defect-creation-date-header'><span>" +user.translate('defects.header.creation.date') + "</span></div>");
				columnsUsed++;
			}
			if (settings.defectMetadata.defect_show_actualisation_date && defectMetadataHeader.find('.defect-last-actualisation-date-header').length === 0){
				defectMetadataHeader.append( "<div class='defect-last-actualisation-date-header'><span>" +user.translate('defects.header.actualisation.date') + "</span></div>");
				columnsUsed++;
			}
			// if (settings.defectMetadata.defect_show_fixed_date && defectMetadataHeader.find('.defect-fixed-date-header').length === 0){
			// 	defectMetadataHeader.append( "<div class='defect-fixed-date-header'><span>" +user.translate('defects.header.fixed.date') + "</span></div>");
			// 	columnsUsed++;
			// }
			if (!user.isPrincipal() && settings.defectMetadata.defect_show_escalationlevel && defectMetadataHeader.find('.defect-escalationlevel-header').length === 0){
				defectMetadataHeader.append( "<div class='defect-escalationlevel-header'><span>" +user.translate('defects.header.escalation.level') + "</span></div>");
				columnsUsed++;
			}
			if (settings.defectMetadata.defect_show_deadline && defectMetadataHeader.find('.defect-deadline-date-header').length === 0){
				defectMetadataHeader.append( "<div class='defect-deadline-date-header'><span>" +user.translate('defects.header.deadline') + "</span></div>");
				columnsUsed++;
			}
			if(screen.width <= 1000) {
				columnsUsed = 0;
			}
			this.$projectTitle.text(currentProject.get('name'));
			this.$projectTitle.css('width', 'calc(90% - ' + columnsUsed * 140 + 'px)');
			$('.defect-data').css('width', 'calc(90% - ' + columnsUsed * (window.innerWidth > 1400 ? 140 : 112) + 'px)');
		},

		toggleFavouriteDefects: function() {
			this.refreshFavouriteFilter(currentDefects.elementFilter.favourite, false);
			this.filter(null,null,true);
		},

		refreshFavouriteFilter: function(value, lockState) {
			if (!user.isPrincipal() && !user.isSubcontractor()) {
				var favouriteSet = false;
				if (!currentDefects.elementFilter || value == null) {
					favouriteSet = false;
				} else if(!lockState){
					favouriteSet = !value;
				} else {
					favouriteSet = value;
				}
				$('.filter-only-favourite-yes').toggle(favouriteSet);
				$('.filter-only-favourite-no').toggle(!favouriteSet);
			}
		},

		hasMoreItemsInList: function () {
			return this.keyIndex < this.groupKeys.length-1 || this.keyIndex == this.groupKeys.length-1 && this.itemIndex < currentDefects.defectsGrouped[this.groupKeys[this.keyIndex]].length;
		},

		renderDefect: function (defect, group, open) {
			var classes = '';
			if(this.isJourFixeInspection && defect.get('initialInspectionDate') && defect.get('initialInspectionDate') !== currentInspection.get('date')) {
				classes = 'class="fromAnotherInspection"';
			}
			var $item = $('<li ' + classes + ' data-group="' + group + '">').appendTo(this.$list).toggle(open === 'true');

			$item.click(function() {
				localStorage.setItem('listSize', this.itemCount);
				localStorage.setItem('scroll', this.$list.scrollTop());
			}.bind(this));

			$item = $('<div class="ui-grid-a ui-corner-all">').appendTo($item);
			$('<input type="checkbox" class="selectItem ui-btn ui-block-a">')
				.attr('data-id', defect.id)
				.appendTo($item);

			var settings = user.get('settings');
			var addPrincipalId = (settings && settings.addPrincipalId);

			var isChecked = _.contains(this.checkedIds, defect.get('projectId'));

			var $link = $('<div>').attr({
				'class': 'defect-entry ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r' + (defect.isNew() ? ' ui-disabled' : '' + (this.areAllDefectsSelected ? ' highlight-all' : '')) + (isChecked ? ' highlight-defect' : ''),
				'data-id': defect.get('projectId')
			})
				.addClass('list-icon')// list-icon-status-' + defect.get('status'))
				.attr('style', (defect.get('isFavourite') && !user.isSubcontractor() && !user.isPrincipal()) ? 'background-image: url(' +  '/onlineBauabnahme/images/icons/starCombined.png), url(' +  '/onlineBauabnahme/images/status-icons/combined/' + defect.getCombinedStatusIcon() + ')': 'background-image:url(' +  '/onlineBauabnahme/images/status-icons/combined/' + defect.getCombinedStatusIcon() + ')')
				.css({
					backgroundPositionX: ((defect.get('terminated') || defect.get('requests') === 4) ? '8px' : ''),
					backgroundSize: ((defect.get('terminated') || defect.get('requests') === 4) ? '22px' : '')
				})
				//.append($('<span class="ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-' + defect.get('status') + '">'))
				.html(defect.getLabelHTML(currentDefects.elementFilter.external, addPrincipalId))
				.appendTo($item);

			if ($link[0].scrollWidth > $link.innerWidth()) {
				$link.attr('title', defect.getLabel(currentDefects.elementFilter.external, addPrincipalId));
			}

			var hasFiles = _.contains(currentProject.get('fileDefectIds'), defect.id),
				hasComment =
					defect.has('h') && !!defect.get('h') ||
					!user.isSubcontractor() && defect.has('cmt') && !!defect.get('cmt') ||
					!user.isPrincipal() && defect.has('scmt') && !!defect.get('scmt'),
				costType = currentProject.get('costType');
			var symbols = [];
			if (costType === 'SEPARATE' && !user.isSubcontractor() && defect.has('costAG') && defect.get('costAG') > 0) {
				symbols.push('<span class="costIndicator" title="AG">' + defect.get('costAG') + ' €</span>');
			}
			if (!user.isSubcontractor() && defect.has('cost') && defect.get('cost') > 0) {
				if (!(costType === 'SEPARATE' && currentDefects.elementFilter.external)) {
					symbols.push('<span class="costIndicator" title="AN">' + (currentProject.get('costType') !== 'GLOBAL' ? defect.get('cost') + ' €' : user.translate('defect.significant')) + '</span>');
				}
			}
			//if (defect.get('principalStatus') === 'accepted') {
			//	symbols.push('<span class="principal-accepted-symbol"></span>');
			//}
			//if (defect.get('principalStatus') === 'discount') {
			//	symbols.push('<span class="principal-discount-symbol"></span>');
			//}
			//if (defect.get('principalStatus') === 'rejected') {
			//	symbols.push('<span class="principal-rejected-symbol"></span>');
			//}
			if (hasComment) {
				symbols.push('<span class="comment-symbol"></span>');
			}
			if (hasFiles) {
				symbols.push('<span class="fileIndicator"></span>');
			}

			if (symbols.length > 0) {
				var $container = $('<span class="symbols">').appendTo($link);
				$container.append(symbols.join(' '));
			}
			if (hasComment) {
				var ct = '';
				var scmt = (defect.get('scmt') ? defect.get('scmt').split(',') : null), cmt = (defect.get('cmt') ? cmt = defect.get('cmt').split(',') : null);
				if(!user.isPrincipal() && scmt && scmt.length > 0) {
					ct += user.translate('defect.log.comment.ANComment') + '\n'
					scmt.forEach(function (c, i) {
						ct += c + (i  === (scmt.length - 1) ? '' : '\n');
					})
				}
				if(!user.isSubcontractor() && cmt && cmt.length > 0) {
					ct += (ct.length > 0 ? '\n\n' : '') + user.translate('defect.log.comment.AGComment') + '\n'
					cmt.forEach(function (c, i) {
						ct += c + (i  === (cmt.length - 1) ? '' : '\n');
					})
				}
				$container.find('.comment-symbol').attr('data-before', 'i').click(_.bind(function () {
					this.router.defectPage.$el.one('pageshow', _.bind(this.router.defectPage.showCommentDialog, this.router.defectPage));
				}, this)).attr('title', ct);
			}

		},

		renderDivider: function (key, group) {
			$('<li data-role="list-divider" data-group="' + group + '" data-open="' + (this.groupToggles && this.groupToggles.get(group) ? this.groupToggles.get(group) : 'true') + '"></li>').text(key).appendTo(this.$list);
		},

		filterParams: function () {
			var result = '';
			if (this.orderBy) {
				result += '&orderBy=' + this.orderBy.toUpperCase();
			}
			if (this.groupBy) {
				result += '&groupBy=' + this.groupBy.toUpperCase();
			}
			if (currentDefects.elementFilter.external) {
				result += '&external=1'
			}
			if (currentDefects.elementFilter.favourite) {
				result += '&isFavourite=1'
			}
			if (this.filterMode === 'detail') {
				return result;
			}
			if (currentDefects.elementFilter.subcontractor !== null) {
				if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
					result += '&crew=' + currentDefects.elementFilter.subcontractor;
				} else {
					result += '&subcontractor=' + currentDefects.elementFilter.subcontractor;
				}
			}
			if (currentDefects.elementFilter.inspection !== null) {  //
				result += '&inspection=' + currentDefects.elementFilter.inspection;
			}
			if (currentDefects.elementFilter.status !== null) {
				result += '&status=' + currentDefects.elementFilter.status;
			}
			if (currentDefects.elementFilter.unit !== null) {
				result += '&unit=' + currentDefects.elementFilter.unit;
			}
			if (currentDefects.elementFilter.subcontractorStatus !== null) {
				result += '&subcontractorStatus=' + currentDefects.elementFilter.subcontractorStatus;
			}
			if (currentDefects.elementFilter.principalStatus !== null) {
				result += '&principalStatus=' + currentDefects.elementFilter.principalStatus;
			}
			if (currentDefects.elementFilter.cost !== null) {
				result += '&cost=' + currentDefects.elementFilter.cost.toUpperCase();
			}
			if (currentDefects.elementFilter.costAG !== null) {
				result += '&costAG=' + currentDefects.elementFilter.costAG.toUpperCase();
			}
			if (currentDefects.elementFilter.extraStatus !== null) {
				result += '&es=' + currentDefects.elementFilter.extraStatus;
			}
			if (currentDefects.elementFilter.type !== null) {
				result += '&type=' + currentDefects.elementFilter.type;
			}
			if (currentDefects.elementFilter.requests !== null) {
				result += '&requests=' + currentDefects.elementFilter.requests;
			}
			if (currentDefects.elementFilter.overdue !== null) {
				result += '&overdue=' + (currentDefects.elementFilter.overdue ? 1 : 0);
			}
			if (currentDefects.elementFilter.agoverdue !== null) {
				result += '&agoverdue=' + (currentDefects.elementFilter.agoverdue ? 1 : 0);
			}
			if (currentDefects.elementFilter.hasNewSCMT !== null) {
				result += '&hasNewSCMT=' + (currentDefects.elementFilter.hasNewSCMT);
			}
			if (currentDefects.elementFilter.allSubChanges !== null) {
				result += '&allSubChanges=' + (currentDefects.elementFilter.allSubChanges);
			}
			return result;
		},
		refreshSubcontractorFilterList: function (value) {
			if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
				if (value === null) {
					this.$subcontractorFilter.val('');
				} else {
					this.$subcontractorFilter.val(value);
				}
			} else {
				if (value === null) {
					this.$subcontractorFilter.val('');
				} else if (value === 'null') {
					this.$subcontractorFilter.val(user.translate('defects.filter.emptysubcontractor'));
				} else {
					var subcontractor = (value ? subcontractors.findWhere({ id: value }) : null);
					this.$subcontractorFilter.val(subcontractor ? subcontractor.getLabel() : value);
				}
			}
		},
		refreshInspectionFilterList: function (value) {
			var label;
			if (value === 'null') {
				label = user.translate('defects.filter.emptyinspection');
			} else if (value === null) {
				label = '';
			} else if(value !== undefined) {
				label = inspections.labelIndex[inspections.indexOf(inspections.get(value))];
			}
			this.$inspectionFilter.val(label);
		},

		refreshTypeFilterList: function (value) {
			this.$defectTypeFilter.val(value || '').selectmenu('refresh');
			if (this.$defectTypeFilter.val() === user.translate('defects.filter.anydefecttype') || this.$defectTypeFilter.val() === '') {
				this.$defectTypeFilter.parent().find($('span')).css({'color':'#aaa', 'opacity':'0.5'});
			}
		},

		refreshStatusFilterList: function (value) {
			this.$statusFilter.val(value);
			this.$statusFilter.selectmenu('refresh');
			if (this.$statusFilter.val() === user.translate('defects.filter.anystatus') || this.$statusFilter.val() === '') {
				this.$statusFilter.parent().find($('span')).css({'color':'#aaa', 'opacity':'0.5'});
			}
		},

		remove: function() {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					self.resizeLocationInput();
					var listMargin = parseInt(self.$list.css('margin-bottom')) + parseInt(self.$list.css('margin-top'));
					self.$list.hide();
					var last = $('.list-separator:visible');
					//var visible = self.$('.ui-content > *:visible');
					//var last = $(visible[visible.length - 1]);
					var pageOffset = parseInt(self.$el.css('padding-top').split('px')[0]);
					var height = Math.floor(self.$el.height() - (last.length > 0 ? last.position().top : 52) - (last.outerHeight(true) || 0) + pageOffset - listMargin - self.$('.listheader').height());

					if ($('#inspection .inspectionDetails')[0].offsetHeight > 0) {
						if (self.$el.width() < 500) {
							height += $('#inspection .inspectionDetails')[0].offsetHeight;
						} else {
							height += $('#inspection .inspectionDetails')[0].offsetHeight - 57;
						}

					}
					self.$list.height(height).show();
				}
			}, this);
		},

		resizeLocationInput: function () {
			//var availWidth = this.$locationFilter.width();
			var availWidth = 100;
			var locationChoice = this.$locationFilter.find('.ui-select, button');
			locationChoice.each(function (index) {
				var optionSize = (availWidth / locationChoice.length);//+ (index === locationChoice.length - 1 ? -1 : 0));
				$(this).css('width', (optionSize > 100 ? 100 : optionSize) + "%");
			});
		},

		refreshSelections: function (mode) {
			if (mode === 'select') {
				var filtered = currentDefects.filtered();
				if (filtered.length > 0) {
					var shownDefects = $('.list li a');
					this.checkedIds = _.reduce(filtered, function (result, defect) {
						result.push(defect.id);
						shownDefects.addClass("highlight-all");
						return result;
					}, []);
					this.refreshSelections();
					this.areAllDefectsSelected = true;
					this.$defectsCount.text(this.checkedIds.length);
					this.$selectAll.addClass('deselect').text(user.translate('defects.menu.deselect'));
					$('.defects-quick-actions').removeClass("hide-menu");
					$('.defects-quick-actions').addClass("active-menu");
					this.$defectListHeader.find('input.select-all-defects').prop('checked', true).checkboxradio('refresh');
				} else {
					this.refreshSelections('deselect');
				}
			} else if (mode === 'deselect') {
				$('.list li a').removeClass("highlight-defect");
				$('.list li a').removeClass("highlight-all");
				this.checkedIds = [];

				$('.defects-quick-actions').addClass("hide-menu");

				this.areAllDefectsSelected = false;
				this.refreshSelections();
				this.$selectAll.removeClass('deselect').text(user.translate('defects.menu.selectall'));
				this.$defectListHeader.find('input.select-all-defects').prop('checked', false).checkboxradio('refresh');
			} else if (mode === 'keepSelectedAfterEdit') {
				currentDefects.resetFilter();
				currentDefects.elementFilter.customDefects = [];
				_.forEach(this.checkedIds, function (cid) {
					currentDefects.elementFilter.customDefects.push(currentDefects.get(cid).get('id'));
				})
				this.resetFilters();
				this.refreshSelections();
			} else {

				this.$list.find('input.selectItem').prop('checked', false);
				_.each(this.checkedIds, function (id) {
					this.$list.find('input.selectItem[data-id="' + id + '"]').prop('checked', true);
				}, this);
			}
			this.refreshDownloadOption();
		},

		refreshDownloadOption: function () {
			$('.downloadheading').text(user.translate(this.checkedIds.length ? 'defects.menu.downloadselected' : 'defects.menu.download'));
		},

		triggerMore: _.debounce(function () {
			this.$moreRows.find('button').click();
		}, 500),

		postSelectedIds: function () {
			var filtered = currentDefects.filtered();

			return $.ajax({
				url:   '/onlineBauabnahme/api/setDefectSelection',
				type: 'POST',
				data: JSON.stringify({
					selectedIds:
						!!this.checkedIds && this.checkedIds.length > 0 /*&& filtered.length != this.checkedIds.length*/
							? this.checkedIds
							: this.filterMode === 'filter'
								? null
								: _.pluck(filtered, 'id'),
					filterName:
						this.filterMode === 'search'
							?	currentDefects.elementFilter.search
							: 	this.filterMode === 'detail'
								? this.router.filterPage.$presetList.find('option:selected').text()
								: null
				}),
				dataType:    'json',
				contentType: 'application/json',
				processData: false
			});
		},

		correctDates: function (dates) {
			if (dates.from) {
				dates.from.setHours(0);
			}
			if (dates.to) {
				dates.to.setHours(23);
				dates.to.setMinutes(59);
				dates.to.setSeconds(59);
			}
			return dates;
		}
	});
});
