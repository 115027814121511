define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment', './InspectionsPageHtml', '../view/BaseView',
	'../model/user', '../model/currentProject', '../model/inspections', '../model/currentInspection', '../model/currentDefects', '../helper/watchdog', '../helper/shortcuts/shortcuts', '../helper/backboneReactCommunicationUtils',
	'../view/ReducedDefectsBanner'
], function (
		$, _, Backbone, moment, Template, BaseView,
		user, currentProject, inspections, currentInspection, currentDefects, watchdog, shortcuts, backboneReactCommunicationUtils,
		ReducedDefectsBanner
) {
	return BaseView.extend({

		id: 'inspections',

		attributes: {
			'data-role': 'page'
		},

		template: _.template(Template),

		events: {
			'orientationchange':                 function() { $.mobile.resetActivePageHeight(); this.resize(); },
			'keyup .search-bar input':               'search',
			'change .unitSelect':  'filterUnit',
			'change .filter':      'filterType',
//			'change .finished':    'filterFinished',
//			'change .archive':     'filterArchive',
			'vclick .header-info': function() {
				alertPopup(user.translate('info.groupNote').replace('%', currentProject.get('groupName')));
			},
			'vclick .statusAll': function (e) {
				e.preventDefault();
				e.stopPropagation();
				var inspectionId = $(e.currentTarget).parent().parent().attr('data-id');

				currentDefects.resetFilter();
				currentDefects.elementFilter.inspection = inspectionId;
				window.navigateCallback(backboneReactCommunicationUtils.getCustomerProjectConsideringInspection()+'/defects');
			},
			'vclick .moreRows button': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.renderList({ dontEmpty: true });
				var $children = this.$list.children();
				var $lastElement = $children.get($children.length - 1);
				this.$list.scrollTo($lastElement, 500);
			},
			'vclick .setFromTo': function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.$fromToDialog.show().popup('open');
				var $buttons = this.$fromToDialog.find('button');
				$buttons.one('vclick', _.bind(function (e) {
					e.preventDefault();
					e.stopPropagation();
					if ($(e.currentTarget).hasClass('ok')) {
						this.$setFromToButton.text(user.translate('filter.creationdate.button') + ': ' + (this.from ? this.from : '-') + ' | ' + (this.to ? this.to : '-'));
						this.filterDate();
					} else if ($(e.currentTarget).hasClass('cancel')) {
						var text = this.$setFromToButton.text();
						var from = text.split(':')[1].trim().split('|')[0].trim()
						var to = text.split(':')[1].trim().split('|')[1].trim()
						this.from = from;
						this.to = to;
						this.$fromToDialog.find('input.from').val(from);
						this.$fromToDialog.find('input.to').val(to);
					}
					$buttons.off('vclick');
					this.$fromToDialog.popup('close');
				}, this));
			},
			'change .fromToDialog input': function (e) {
				var changed = $(e.target);
				var from = this.$fromToDialog.find('input.from');
				var to = this.$fromToDialog.find('input.to');
				if (changed.hasClass('from')) {
					to.attr('min', changed.val());
					if ((to.val() === '' || to.val() < changed.val()) && changed.val() !== '') to.val(changed.val());
				} else {
					from.attr('max', changed.val());
					if ((from.val() === '' || from.val() > changed.val()) && changed.val() !== '') from.val(changed.val());
				}
				this.from = from.val();
				this.to = to.val();
			}
		},

		showFirstCount: 0,
		itemCount: 0,
		pageSize: 30,
		comparator: $.noop,

		initialize: function () {
			this.resize = _.bind(this.resize, this);

			this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);
			this.reducedDefectsBanner = new ReducedDefectsBanner();
			this.$reducedDefectsBanner = this.$('.reducedDefectsBanner');

			this.unit                  = this.$('.unit');
//			this.finishedFilter        = this.$('.finished');
//			this.archiveFilter         = this.$('.archive');
			this.typeFilter            = this.$('.filter.type');
			this.$inspectionTypeFilter = this.$('.filter.inspection-type');
			this.$list                 = this.$('.list');
			this.$includeFulltext      = this.$('.includeFulltext');
			this.creationDateFilter = this.$('.filter.creationDate');

			this.$fromToDialog = this.$('.fromToDialog');
			this.$setFromToButton = this.$('.setFromTo');

			this.$el.on('pageshow', this.resize);
    		$(window).on('throttledresize', this.resize);
			this.listenTo(watchdog, 'change:connected', _.bind(function () {
				this.$('.back').toggleClass('ui-disabled', !watchdog.isConnected());
			}, this));
		},

		remove: function() {
			$(window).off('throttledresize', this.resize);
			Backbone.View.prototype.remove.apply(this, arguments);
		},

		render: function () {
			this.reducedDefectsBanner.renderReducedDefectsBanner(this.$reducedDefectsBanner, () => { this.render(); });

			$('.header-info').toggle(currentProject.get('groupFilterActive'));

			this.refreshLocation();
//			this.finishedFilter.prop('checked', !inspections.filter.onlyPending).checkboxradio('refresh');
//			this.archiveFilter.prop('checked', inspections.filter.archive).checkboxradio('refresh');
			this.renderInspectionTypeFilter();

			this.countInfo = {};
			currentDefects.each(function (defect) {
				var defectInspections = defect.get('inspections');
				_.each(defectInspections || [null], function (inspection) {
					if (!this.countInfo[inspection]) {
						this.countInfo[inspection] = {
							total: 0,
							pending: 0
						};
					}

					this.countInfo[inspection].total++;
					if (defect.isStatusPending()) {
						this.countInfo[inspection].pending++;
					}
				}, this);
			}, this);

			this.refreshList();

			return this;
		},

		search: function (e) {

			inspections.filter.search = $(e.target).val();
			inspections.filter.includeFulltext = this.$includeFulltext.is(':checked');
			this.refreshList();
		},

		resize: function () {
			_.defer(function (self) {
				if (self.$el.hasClass('ui-page-active') && !self.$el.hasClass('hide-page')) {
					self.resizeLocationInput();
					var listMargin = parseInt(self.$list.css('margin-bottom')) + parseInt(self.$list.css('margin-top'));
					self.$list.hide();
					var last = self.$('.ui-content > *:visible').eq(-1);
					var pageOffset = parseInt(self.$el.css('padding-top'));
					var height = Math.floor(self.$el.height() - last.position().top - last.outerHeight(true) +
						pageOffset - listMargin) - 1;
					self.$list.height(height).show();
				}
			}, this);
		},

		resizeLocationInput: function () {
			var availWidth, filterTypeWidth, filterInspectionTypeWidth, locationElementWidth;
			availWidth = this.$('.unit').width();
			var locationChoice = this.$('.unit select.unitSelect').closest('.ui-select');
			if ($('body').width() < 900) {
				filterTypeWidth = availWidth * 0.25 - 1;
				filterInspectionTypeWidth = availWidth * 0.75 - 1;
				locationElementWidth = Math.floor(availWidth / locationChoice.length) - 1;
			} else {
				filterTypeWidth = 180;
				filterInspectionTypeWidth = Math.max(availWidth * 0.35 - 1 - filterTypeWidth, 180);
				availWidth -= (filterTypeWidth + filterInspectionTypeWidth);
				locationElementWidth = Math.floor(availWidth / locationChoice.length) - 2;
			}

			this.$('.filter.type').closest('.ui-select').width(Math.floor(filterTypeWidth));
			this.$('.filter.inspection-type').closest('.ui-select').width(Math.floor(filterInspectionTypeWidth));
			locationChoice.each(function (index) {
				$(this).css('width', locationElementWidth);
			});
		},

		filterDate: function () {
			var from = this.$fromToDialog.find('input.from').val();
			var to = this.$fromToDialog.find('input.to').val();
			if (from !== '' && to !== '') {
				inspections.filter.from = from;
				inspections.filter.to = to;
			} else {
				delete inspections.filter.from;
				delete inspections.filter.to;
			}
			this.refreshList();
		},

		filterUnit: function (e) {
			inspections.filter.unit = $(e.target).val();
			this.render();
		},

		filterType: function () {
			delete inspections.filter.external;
			delete inspections.filter.finished;
			delete inspections.filter.archive;
			switch (this.typeFilter.val()) {
			case 'all':
				inspections.filter.archive = false;
				break;
			case 'external':
				inspections.filter.external = true;
				inspections.filter.archive = false;
				break;
			//case 'externalfinished':
			//	inspections.filter.external = true;
			//	inspections.filter.finished = true;
			//	inspections.filter.archive = false;
			//	break;
			case 'internal':
				inspections.filter.external = false;
				inspections.filter.archive = false;
				break;
			case 'archived':
				inspections.filter.archive = true;
				break;
			}

			var filterInspectionType = this.$inspectionTypeFilter.val();
			if (filterInspectionType) {
				inspections.filter.type = filterInspectionType;
			} else {
				inspections.filter.type = null;
			}

			this.refreshList();
		},

//		filterFinished: function () {
//			var checked = this.finishedFilter.is(':checked');
//			inspections.filter.onlyPending = !checked;
//			if (checked && this.archiveFilter.is(':checked')) {
//				inspections.filter.archive = false;
//				this.archiveFilter.prop('checked', false).checkboxradio('refresh');
//			}
//			this.refreshList();
//		},
//
//		filterArchive: function () {
//			var checked = this.archiveFilter.is(':checked');
//			inspections.filter.archive = checked;
//			if (checked && this.finishedFilter.is(':checked')) {
//				inspections.filter.onlyPending = true;
//				this.finishedFilter.prop('checked', false).checkboxradio('refresh');
//			}
//			this.refreshList();
//		},
		
		refreshLocation: function () {
			var $container = this.unit.controlgroup('container');
			if (currentProject.isNew()) {
				$container.empty();
			} else {

				var unit = currentProject.get('unitTree');
	
				if (currentInspection.isNew()) {
					currentInspection.set('unit', inspections.filter.unit);
				}
	
				var parts = (inspections.filter.unit || '').split('-');
	
				var choices = [];
				for (var i = 0; i <= parts.length && unit.children; i++) {
					var choice = $('<select class="unitSelect">');
					$('<option>').text(currentProject.getStructureKey(i)).val(unit.path).appendTo(choice);
					_.each(_.compact(_.values(unit.children)), function (child) {
						$('<option>')
							.text(child.name)
							.val(child.path)
							.appendTo(choice);
					}, this);
					choices.push(choice);
					if (i < parts.length && parts[i]) {
						unit = unit.children[parts[i]];
						choice.val(unit.path);
					} else {
						break;
					}
				}
	
				this.unit.find('.unitSelect').remove();
				for (var i = choices.length - 1; i >= 0; i--) {
					choices[i]
						.prependTo($container)
						.selectmenu();
				}
			}
			this.unit.controlgroup('refresh');

			this.resizeLocationInput();
			
			$('span.unitSelect').each(function () {
				
				if(_.contains($(this).text(), user.translate('defects.anylocation'))) {
					$(this).css({'color':'#aaa', 'opacity':'0.5'});
				}
			});
		},

		renderInspectionTypeFilter: function () {
			this.$inspectionTypeFilter.empty();

			var typesOfInspections = {};
			inspections.each(function(i) {
				typesOfInspections[i.get('type')] = true;
			});

			var visibleInspectionTypes = inspections.getVisibleInspectionTypes(_.keys(typesOfInspections));
			$('<option>')
				.val('').text(user.translate('inspections.filter.types.all'))
				.appendTo(this.$inspectionTypeFilter);
			_.each(visibleInspectionTypes, function (i) {
				$('<option>')
					.val(i.id).text(i.extendedName)
					.appendTo(this.$inspectionTypeFilter);
			}, this);
			this.$inspectionTypeFilter.val(inspections.filter.type || '');
			this.$inspectionTypeFilter.selectmenu().selectmenu('refresh');
		},

		refreshList: function (options) {
			if (!this.$list) {
				return;
			}

			var filteredInspections = _(inspections.models).filter(inspections.matchesFilter, inspections).valueOf();
			var modelsAttributes = [];
			for (var i = 0; i < filteredInspections.length; i++) {
				modelsAttributes.push(filteredInspections[i].attributes);
			}
			this.inspectionsKeys = _.filter(_.pluck(modelsAttributes, 'id'), function(i) {
				return (i !== undefined && i !== null);
			});

			this.itemCount = 0;

			if (options && options.newListContext) {
				this.showFirstCount = 0;
			}

			this.renderList();
			$('span.filter').each(function () {
				if($(this).text() === user.translate('inspections.filter.all') || $(this).text() === user.translate('inspections.filter.types.all')) {
					$(this).css({'color':'#aaa', 'opacity':'0.5'});
				}
			})
		},

		renderList: function (options) {
			options = options || {};
			if (!options.dontEmpty) {
				this.$list.empty();
			}

			if (inspections.size() === 0) {
				$('<li class="none">').text(user.translate('inspections.noinspections')).appendTo(this.$list);
			} else if (this.inspectionsKeys.length === 0) {
				$('<li class="none">').text(user.translate('inspections.noinspectionsmatching')).appendTo(this.$list);
			}
			var smallScreen = (screen.width < 500);
			for (var i = 0; i < this.pageSize && this.hasMoreItemsInList(); i++, this.itemCount++) {
				this.renderInspection(inspections.get(this.inspectionsKeys[this.itemCount]), smallScreen);
			}

			if (this.hasMoreItemsInList()) {
				if (!options.noRecursion) {
					var recursionOptions = {
						dontEmpty:   true,
						noRefresh:   true,
						noRecursion: true
					};
					while(this.itemCount < this.showFirstCount && this.hasMoreItemsInList()) {
						this.renderList(recursionOptions);
					}
					if (this.hasMoreItemsInList()) {
						if (this.$moreRows) {
							this.$moreRows.detach();
							this.$list.append(this.$moreRows);
						} else {
							this.$moreRows = $('<li class="moreRows"><button>' + user.translate('inspections.more') + '</button></li>').appendTo(this.$list).enhanceWithin();
						}
					}
				}

			} else if (!options.noRecursion && this.$moreRows) {
				this.$moreRows.detach();
			}

			if (!options.noRecursion) {
				this.showFirstCount = this.itemCount;
			}

			if (!options.noRefresh) {
				this.$list.listview('refresh');
			}
		},

		hasMoreItemsInList: function () {
			return this.itemCount < this.inspectionsKeys.length;
		},

		showInspection: function (e, id) {
			e.preventDefault();
			e.stopPropagation();
			currentDefects.resetFilter();
			currentDefects.elementFilter.inspection = id;
			window.lastUnit = ''; // reset last location
			var path = backboneReactCommunicationUtils.getCustomerProject();
			window.navigateCallback(path+"/inspection/"+id);
		},

		renderInspection: function (inspection, smallScreen) {
			var pendingCount = 0;
			var totalCount = 0;

			if (this.countInfo[inspection.id]) {
				pendingCount = this.countInfo[inspection.id].pending;
				totalCount = this.countInfo[inspection.id].total;
			}

			if (smallScreen) {
				var $item = $('<li data-id="' + inspection.id + '"></li>').appendTo(this.$list);

				$item.append($('<a class="inspectionCompact ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r">')
					.on('click', function (e) {
						this.showInspection(e, inspection.id);
					}.bind(this))
					.text(

						inspection.get('title')

					));

				$item.find('a').append(
					'<span class="inspectionCompactDate">' + moment(inspection.get('date')).format('DD.MM.YYYY') + '</span>' +
					'<div class="statusAll ui-btn ui-block-a' + (!totalCount ? ' noDefects' : '') + '">' +

					'<span class="count">' +
					'<span class="pending">' + pendingCount + '</span>' +
					'<span class="slash">/</span>' +
					'<span class="total">' + totalCount + '</span>' +
					'</span>' +

					'</div>'

				);

			} else {
				var $item = $('<li data-id="' + inspection.id + '"></li>').appendTo(this.$list);
				$item = $('<div class="ui-grid-a"></div>').appendTo($item);
				$item.append('<button class="statusAll ui-btn ui-block-a' + (!totalCount ? ' noDefects' : '') + '">' +
					'<span class="ui-btn-icon-notext ui-icon-arrow-r"></span>' +
					'<span class="count">' +
					'<span class="pending">' + pendingCount + '</span>' +
					'<span class="slash">/</span>' +
					'<span class="total">' + totalCount + '</span>' +
					'</span>' +
					'</button>');

				var protocolNr;
				if(user.get('customer') === 'wienerlinien' && inspection.get('type') && inspection.get('type') === 'bewehrungsabnahme') {
					$.get( '/onlineBauabnahme/api/inspection/' + inspection.id).then(function (i) {
						if(i.customerProperties && i.customerProperties.protocolnr) protocolNr = i.customerProperties.protocolnr;
					}.bind(this)).always(function () {
						$item.append($('<a class="ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r">')
							.on('click', function (e) {
								this.showInspection(e, inspection.id);
							}.bind(this))
							.html(
								moment(inspection.get('date')).format('DD.MM.YYYY') + ', ' +
								inspection.get('title') +
								(protocolNr && protocolNr !== '' ? ' - <b>Protokoll-Nr.: ' + protocolNr + '</b>' : '') +
								(inspection.get('finished') ? ' (' + user.translate('inspections.filter.finished') + ')' : '')
							));
					}.bind(this));
				} else {
					$item.append($('<a class="ui-btn ui-btn-a ui-block-b ui-btn-icon-right ui-icon-carat-r">')
						.on('click', function (e) {
							this.showInspection(e, inspection.id);
						}.bind(this))
						.text(
							moment(inspection.get('date')).format('DD.MM.YYYY') + ', ' +
							inspection.get('title') +
							(inspection.get('finished') ? ' (' + user.translate('inspections.filter.finished') + ')' : '')
						));
				}
				
			}

		}

	});
});
